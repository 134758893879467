import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as d3 from 'd3'

import moment from 'moment'
import { Overlays } from 'utils'

function Timeline(props) {
    const { t } = useTranslation()
    const { data: raw, loading, error } = props

    const [data, setData] = useState([])
    const [data_shoal, setData_shoal] = useState([])

    const threshold = () => -props.dockDepth + +props.tolerance
    const threshold_shoal = () => {
        let value = -props.dockDepth + +props.tolerance
        if (props.dockShoal !== undefined && props.dockShoal !== 0) {
            value = -props.dockShoal + +props.tolerance
        }
        return value
    }

    const margin = { top: 20, right: 20, bottom: 50, left: 50 }
    const width = props.width - margin.left - margin.right
    const height = props.height - margin.top - margin.bottom

    const xScale = d3.scaleTime().range([0, width])
    const select = selector =>
        selector ? d3.select(`#timeline ${selector}`) : d3.select('#timeline')
    const selectAll = selector =>
        selector ? d3.selectAll(`#timeline ${selector}`) : d3.selectAll('#timeline')

    const format = raw => {
        if (!raw || raw.length === 0) return { grouped: [], domain: [] }
        raw = raw.sort((a, b) => a.date - b.date)
        const domain = [raw[0].date, raw[raw.length - 1].date]

        const grouped = []
        let navigable,
            currentIndex = -1
        raw.forEach(d => {
            let tide = d.wlo !== 0 ? d.wlo : d.wlp > d['wlf-spine'] ? d['wlf-spine'] : d.wlp
            if (!tide) return
            const boatDraft = tide - props.boatDraft
            if (
                (boatDraft >= threshold() && navigable !== true) ||
                (boatDraft < threshold() && navigable !== false)
            ) {
                navigable = boatDraft >= threshold()
                currentIndex++
                grouped[currentIndex] = {
                    navigable,
                    boatDraft_min: boatDraft,
                    min: d.date,
                    max: null,
                }
            }
            grouped[currentIndex].max = d.date
            grouped[currentIndex].boatDraft_max = boatDraft
        })

        return { grouped, domain }
    }

    const format_shoal = raw => {
        if (!raw || raw.length === 0) return { grouped_shoal: [] }
        if (!props.dockShoal && props.dockShoal === 0) return { grouped_shoal: [] }
        raw = raw.sort((a, b) => a.date - b.date)

        const grouped_shoal = []
        let navigable,
            currentIndex = -1
        raw.forEach(d => {
            let tide = d.wlo !== 0 ? d.wlo : d.wlp > d['wlf-spine'] ? d['wlf-spine'] : d.wlp
            if (!tide) return
            const boatDraft = tide - props.boatDraft
            if (
                (boatDraft >= threshold_shoal() && navigable !== true) ||
                (boatDraft < threshold_shoal() && navigable !== false)
            ) {
                navigable = boatDraft >= threshold_shoal()
                currentIndex++
                grouped_shoal[currentIndex] = {
                    navigable,
                    min: d.date,
                    max: null,
                }
            }
            if (currentIndex >= 0) grouped_shoal[currentIndex].max = d.date
        })

        return { grouped_shoal }
    }

    const init = () => {
        const svg = select()
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

        svg.append('g')
            .attr('id', 'xAxis')
            .attr('transform', 'translate(0,' + height + ')')

        svg.append('g')
            .attr('id', 'xAxis-date')
            .attr('transform', 'translate(0,' + (height + 20) + ')')

        svg.append('text')
            .attr('class', 'axis-legend')
            .attr('transform', `translate(${width / 2}, ${height + margin.bottom})`)
            .style('text-anchor', 'middle')
            .text(t('general.date_legend'))

        svg.append('g').attr('id', 'bars')
        svg.append('rect').attr('id', 'past-overlay')
        svg.append('g').attr('id', 'infos')
        svg.append('text')
            .attr('id', 'details')
            .html(`${t('general.min_tide')} : <tspan id="min-tide">-</tspan>`)
            .attr('transform', `translate(${width / 2}, 20)`)
    }

    const update = () => {
        const { grouped, domain } = data
        const { grouped_shoal } = data_shoal

        xScale.domain(domain)

        // AXIS
        select('#xAxis').call(d3.axisBottom(xScale).ticks(d3.timeHour.every(3), '%Hh').tickSize(0))

        select('#xAxis-date').call(
            d3.axisBottom(xScale).ticks(d3.timeDay.every(1), '%d-%m').tickSize(0)
        )

        // BARS
        const attr = {
            w: 1,
            h: 30,
            class: d => {
                let base = 'bar'
                if (d.navigable) base += ' ok'
                if (!props.startHour) return base

                const date = moment(props.startHour)
                const range = [moment(d.min), moment(d.max)]
                if (date.isBetween(range[0], range[1], undefined, '[]')) base += ' active'
                return base
            },
        }

        const bars = select('#bars').selectAll('.bar').data(grouped)

        // Exit
        bars.exit().remove()

        // Enter
        bars.enter()
            .append('line')
            .attr('class', attr.class)
            .attr('data-area', d => new Date(d.max).getTime())
            .attr('x1', d => xScale(d.min))
            .attr('x2', d => xScale(d.max))
            .attr('y1', height - attr.h / 2 - 6)
            .attr('y2', height - attr.h / 2 - 6)
            .attr('stroke-width', attr.h)

        // Update
        bars.transition()
            .attr('class', attr.class)
            .attr('data-area', d => new Date(d.max).getTime())
            .attr('x1', d => xScale(d.min))
            .attr('x2', d => xScale(d.max))

        // INFORMATIONS
        const infos = select('#infos')
            .selectAll('.navigable')
            .data(grouped.filter(g => g.navigable === true))

        // Exit
        infos.exit().remove()

        // Enter
        const infosEnter = infos
            .enter()
            .append('g')
            .attr('class', d => {
                const diff = moment(d.max).diff(moment(props.startHour), 'minutes')
                if (diff <= 2 && diff >= -2) return 'navigable active'
                return 'navigable'
            })
            .attr('data-area', d => new Date(d.max).getTime())
            .attr('transform', d => `translate(${xScale(d.min)}, ${height - attr.h - 6})`)

        const TIME_TICKER_WIDTH = 28
        infosEnter
            .append('rect')
            .attr('class', d =>
                xScale(d.max) - xScale(d.min) > TIME_TICKER_WIDTH * 2 ? 'r-start' : 'r-start hidden'
            )
            .attr('x', 0)
            .attr('y', 0)

        infosEnter
            .append('rect')
            .attr('class', d =>
                xScale(d.max) - xScale(d.min) > TIME_TICKER_WIDTH * 2 ? 'r-end' : 'r-end hidden'
            )
            .attr('x', d => xScale(d.max) - xScale(d.min))
            .attr('y', 0)

        infosEnter
            .append('text')
            .attr('class', 'descr')
            .text(t('general.navigable_zone'))
            .attr('transform', d => `translate(${(xScale(d.max) - xScale(d.min)) / 2}, -25)`)

        infosEnter
            .append('text')
            .attr('class', d =>
                xScale(d.max) - xScale(d.min) > TIME_TICKER_WIDTH * 2 ? 'd-start' : 'd-start hidden'
            )
            .text(d => moment(d.min).format('HH:mm'))
            .attr('transform', `translate(0, -10)`)

        infosEnter
            .append('text')
            .attr('class', d =>
                xScale(d.max) - xScale(d.min) > TIME_TICKER_WIDTH * 2 ? 'd-end' : 'd-end hidden'
            )
            .text(d => moment(d.max).format('HH:mm'))
            .attr('transform', d => `translate(${xScale(d.max) - xScale(d.min)}, -10)`)

        // Update
        infos
            .transition()
            .attr('class', d => {
                const diff = moment(d.max).diff(moment(props.startHour), 'minutes')
                if (diff <= 2 && diff >= -2) return 'navigable active'
                return 'navigable'
            })
            .attr('data-area', d => new Date(d.max).getTime())
            .attr('transform', d => `translate(${xScale(d.min)}, ${height - attr.h - 6})`)

        infos
            .select('.r-start')
            .attr('class', d =>
                xScale(d.max) - xScale(d.min) > TIME_TICKER_WIDTH * 2 ? 'r-start' : 'r-start hidden'
            )
        infos
            .select('.r-end')
            .attr('x', d => xScale(d.max) - xScale(d.min))
            .attr('class', d =>
                xScale(d.max) - xScale(d.min) > TIME_TICKER_WIDTH * 2 ? 'r-end' : 'r-end hidden'
            )

        infos
            .select('.descr')
            .attr('transform', d => `translate(${(xScale(d.max) - xScale(d.min)) / 2}, -25)`)

        infos
            .select('.d-start')
            .text(d => moment(d.min).format('HH:mm'))
            .attr('class', d =>
                xScale(d.max) - xScale(d.min) > TIME_TICKER_WIDTH * 2 ? 'd-start' : 'd-start hidden'
            )

        infos
            .select('.d-end')
            .text(d => moment(d.max).format('HH:mm'))
            .attr('transform', d => `translate(${xScale(d.max) - xScale(d.min)}, -10)`)
            .attr('class', d =>
                xScale(d.max) - xScale(d.min) > TIME_TICKER_WIDTH * 2 ? 'd-end' : 'd-end hidden'
            )

        // INTERACTIONS & OTHERS DISPLAY
        select('#min-tide').text((threshold() + +props.boatDraft).toFixed(2) + ' m')

        select('#past-overlay')
            .attr('width', xScale(moment()))
            .attr('height', attr.h)
            .attr('x', 0)
            .attr('y', height - attr.h - 6)

        selectAll('.bar').on('click', async (e, d) => {
            console.log(e, d)
            if (props.handleAreaClick) {
                const self = d3.select(e.target)
                //if (self.classed('active')) return
                if (!self.classed('ok')) return

                const status = await props.handleAreaClick(
                    [
                        { date: d.min, boatDraft: d.boatDraft_min },
                        { date: d.max, boatDraft: d.boatDraft_max },
                    ],
                    grouped_shoal.filter(g => g.navigable === true)
                )
                if (!status) return
                d3.selectAll(`[data-area]`).classed('active', false)
                d3.selectAll(`[data-area="${self.attr('data-area')}"]`).classed('active', true)
            }
        })
    }

    useEffect(() => {
        if (d3.select('#timeline').html() === '') init()
    }, [])

    useEffect(() => {
        if (!raw || !props.dockDepth || !props.tolerance || !props.boatDraft) {
            setData_shoal([])
            return setData([])
        }
        setData(format(raw))
        setData_shoal(format_shoal(raw))
    }, [raw, props.dockDepth, props.tolerance, props.boatDraft])

    useEffect(() => {
        if (data?.grouped?.length > 0) update()
    }, [data])

    return (
        <div className='timeline-wrap'>
            <svg id='timeline' viewBox={`0 0 ${props.width} ${props.height}`} />
            <Overlays loading={loading} error={error} />
        </div>
    )
}

export default Timeline
