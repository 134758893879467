import { saveAs } from 'file-saver'
import XLSX from 'xlsx'
import { csv } from 'csvtojson'

import moment from 'moment'

/**
 * Transform time series resolution to a given time step in minutes
 * @param {Array} timeSeries : time series data
 * @param {number} timeStep : time step in minutes to apply to data
 */
export function transformTimeStep(timeSeries, timeStep) {
    return timeSeries.filter(d => moment(d.date).minute() % parseInt(timeStep) === 0)
}

/**
 * Downloads tabular data as XLSX file
 * @param {String} filename : base name of output file
 * @param {Object} data : mapping of each column name to array
 * @param {Array} columns : array of column names
 *
 * usage: downloadToXLSX('foo', [{a: 2, b: 'chou'}], ['a', 'b'])
 */
export function downloadToXLSX(filename, data, columns) {
    columns = columns.filter(c => c.export !== false)
    const textData = [
        columns.map(c => c.title).join(','),
        ...data.map(r =>
            columns
                .map(c => {
                    if (c.export && typeof c.export === 'function') return c.export(r[c.dataIndex])
                    if (c.render) return c.render(r[c.dataIndex])
                    return r[c.dataIndex]
                })
                .join(',')
        ),
    ].join('\n')
    // console.log(textData)
    downloadCsvAsXlsx(textData, filename)
}

export function downloadCsvAsXlsx(csvString, filename = 'export') {
    csv()
        .fromString(csvString)
        .then(json => {
            // console.log(json)
            const wb = XLSX.utils.book_new()
            const ws = XLSX.utils.json_to_sheet(json)
            XLSX.utils.book_append_sheet(wb, ws, '')

            const wbout = XLSX.write(wb, {
                bookType: 'xlsx',
                bookSST: true,
                type: 'binary',
            })

            const buf = new ArrayBuffer(wbout.length)

            const view = new Uint8Array(buf)

            for (let i = 0; i !== wbout.length; ++i) view[i] = wbout.charCodeAt(i) & 0xff

            saveAs(new Blob([buf], { type: 'application/octet-stream' }), `${filename}.xlsx`)
        })
}
