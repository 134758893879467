import { Button, Table } from 'antd'
import { Icon } from 'components'
import { useTranslation } from 'react-i18next'
import { downloadToXLSX } from 'utils'

const PQTable = props => {
    const { t } = useTranslation()
    const { title, columns = [], parse, pagination, createButton = false, handleCreate } = props
    let data = props.data

    if (parse && data) data = JSON.parse(data)
    if (!Array.isArray(data) || !data) return <></>
    data = data.map((d, i) => ({ key: i, ...d }))

    const handleDownload = () => {
        downloadToXLSX(title || 'table_download_pq', data, columns)
    }

    return (
        <div className='table-wrap'>
            <Button className='download-table-btn' onClick={handleDownload}>
                <Icon>download</Icon>
                {t('general.actions.download_xlsx')}
            </Button>
            {createButton && (
                <Button className='create-table-btn' onClick={handleCreate}>
                    <Icon>add</Icon>
                    {t('general.actions.create_option')}
                </Button>
            )}
            <Table columns={columns} dataSource={data} pagination={pagination} />
        </div>
    )
}

export default PQTable
