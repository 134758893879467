import { Helmet, Header, Table, EditModal, CreateModal } from 'components'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useAuth } from 'hooks'
import { Service } from 'services'
import { DOCK_LIST_TABLE_COLUMNS } from 'constants/tables'
import { message } from 'antd'

function DockPage() {
    const { t } = useTranslation()
    const [isEditModalVisible, setIsEditModalVisible] = useState(false)
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
    const [selectedDock, setSelectedDock] = useState(null)
    const [dockInfos, setDockInfos] = useState([])
    const [submitResult, setSubmitResult] = useState(null)

    const { isAuthentified, renderAuthRequest } = useAuth()

    const handleEditOpenModal = dockData => {
        setSelectedDock(dockData)
        setIsEditModalVisible(true)
    }

    const handleCreateOpenModal = () => {
        setIsCreateModalVisible(true)
    }

    const handleDisableButton = dockData => {
        const { key, ...updatedDockData } = dockData

        if (updatedDockData.status === 'active') {
            updatedDockData.status = 'disabled'
        } else {
            updatedDockData.status = 'active'
        }

        try {
            const result = Service.update.dock(updatedDockData)
            setSubmitResult(result)
            message.success(t('general.feedback.update.title'))
        } catch (err) {
            console.log(err)
            message.error(t('general.feedback.error.title'))
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const docks = await Service.list.docks()

                setDockInfos(docks)
            } catch (e) {
                console.error(e)
                setDockInfos([])
            }
        }

        if (isAuthentified === true) fetchData()
    }, [isAuthentified, submitResult])

    if (!isAuthentified)
        return (
            <div className='admin-page-login'>
                <Helmet title={t('page_titles.admin')} />
                <Header />
                {renderAuthRequest(t)}
            </div>
        )

    return (
        <div className='admin-page'>
            <Helmet title={t('page_titles.dock')} />
            <Header
                title={t('dock_page.title')}
                subtitle={t('dock_page.subtitle')}
                homeLink
                dashLink
                type='dots'
            />

            {isEditModalVisible && (
                <EditModal
                    baseValues={selectedDock}
                    visible={isEditModalVisible}
                    onClose={() => setIsEditModalVisible(false)}
                    onSubmit={result => setSubmitResult(result)}
                />
            )}

            {isCreateModalVisible && (
                <CreateModal
                    visible={isCreateModalVisible}
                    onClose={() => setIsCreateModalVisible(false)}
                    onSubmit={result => setSubmitResult(result)}
                />
            )}

            <Table
                title={t('admin_page.analysis_table')}
                columns={DOCK_LIST_TABLE_COLUMNS(t, handleEditOpenModal, handleDisableButton)}
                data={dockInfos}
                pagination={false}
                createButton={true}
                handleCreate={handleCreateOpenModal}
            />
        </div>
    )
}

export default DockPage
