import moment from 'moment'
import { Button, message, Modal } from 'antd'
import { HTMLTemplate, mailtoBody } from 'assets/static/mail.html'
import { saveAs } from 'file-saver'
import { Service } from 'services'
import { DATE_FORMAT } from 'constants/app'

const attach = ['/files/attach_1.pdf', '/files/attach_2.pdf', '/files/attach_3.pdf']
const attach_names = [
    "Procedure de depart des navires a fort tirant d'eau et Traverse Nord REV",
    'Bathymetrie2022',
    'Procedures-depart-navires-Foulons',
]

export async function handleAnalysisMailing(analysis, t, img) {
    try {
        const levels_start = await Service.list.levels(analysis.start_manoeuver_hour, [-12, 24])
        const levels_end = await Service.list.levels(analysis.end_manoeuver_hour, [-12, 24])
        const dockInfos = await Service.get.dock(analysis.dock_id)

        const handleEMLExport = async () => {
            const attachs = await loadAttachments()
            const filename = `${analysis.boat_name} - ${moment(analysis.date).format(
                DATE_FORMAT
            )}.eml`
            const subject = `${analysis.boat_name} - ${moment(analysis.date).format(DATE_FORMAT)}`

            const content = [
                `X-Unsent: 1`,
                `Subject: ${subject}`,
                `Content-Type: multipart/mixed; boundary=--boundary_text_string`,
                ``,
                `----boundary_text_string`,
                `Content-Type: text/html; charset=UTF-8`,
                ``,
                `${HTMLTemplate(analysis, levels_start, levels_end, img?.src)}`,
                ...attachs.map((attach, i) =>
                    [
                        ``,
                        `----boundary_text_string`,
                        `Content-Type: application/pdf; name=${attach_names[i]}.pdf`,
                        `Content-Transfer-Encoding: base64`,
                        `Content-Disposition: attachment`,
                        ``,
                        attach.replace('data:application/pdf;base64,', ''),
                    ].join('\n')
                ),
            ]

            // Conditionally add Cc line if email_bccs is not null
            if (dockInfos.email_bccs) {
                content.splice(2, 0, `Cc: ${dockInfos.email_bccs}`)
            }

            saveAs(new Blob([content.join('\n')], { type: 'text/plain;charset=utf-8' }), filename)
        }

        const renderContent = () => (
            <>
                <p>{t('general.actions.mail_descr')}</p>
                <div className='buttons'>
                    <a
                        href={`mailto:?subject=${analysis.boat_name} - ${moment(
                            analysis.date
                        ).format(DATE_FORMAT)}&body=${mailtoBody(analysis)}`}
                        target='_blank'
                        rel='noreferrer'
                        className='ant-btn'
                    >
                        <span>{t('general.actions.mail_mailto')}</span>
                    </a>
                    <Button onClick={handleEMLExport}>{t('general.actions.mail_tpl')}</Button>
                </div>
            </>
        )

        Modal.info({
            title: t('general.actions.mail'),
            closable: true,
            maskClosable: true,
            content: renderContent(),
        })
    } catch (e) {
        message.error(t('general.feedback.error.title'))
    }
}

async function loadAttachments() {
    const blobToBase64 = blob => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result)
            }
        })
    }

    const promises = attach.map(a => fetch(a))

    return new Promise((res, rej) => {
        Promise.all(promises)
            .then(responses => Promise.all(responses.map(r => r.blob())))
            .then(blobs => Promise.all(blobs.map(blobToBase64)))
            .then(base64s => res(base64s))
            .catch(e => rej(e))
    })
}
