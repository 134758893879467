import Icon from '@material-ui/core/Icon'

const DICT = {
    circle: 'fiber_manual_record',
    status: 'wifi_tethering',
    to: 'keyboard_arrow_right',
    link: 'link',
    back: 'keyboard_arrow_left',
    dropdown: 'keyboard_arrow_down',
    save: 'save',
    close: 'close',
    submit: 'send',
    login: 'login',
    logout: 'logout',
    add: 'add',
    edit: 'edit',
    delete: 'delete',
    warning: 'warning',
    see_more: 'more_horiz',
    next: 'keyboard_arrow_right',
    prev: 'keyboard_arrow_left',
    up: 'keyboard_arrow_up',
    down: 'keyboard_arrow_down',
    download: 'download',
    print: 'print',
    user: 'person',
    trad: 'language',
    error: 'error',
    home: 'home',
    loading: 'rotate_left',
    mail: 'send',
    details: 'visibility',
    fullscreen: 'fullscreen',
    '!fullscreen': 'fullscreen_exit',
    infos: 'informations',
    boat: 'directions_boat',
}

const PQIcon = ({ children, unsafe, placeholder, ...props }) => {
    const type = String(children).toLowerCase()

    const computeIcon = () => {
        if (placeholder) return null
        if (type in DICT) return DICT[type]
        if (unsafe) return type
        return 'remove_circle_outline'
    }

    return <Icon {...props}>{computeIcon()}</Icon>
}

export default PQIcon
