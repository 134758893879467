import moment from 'moment'

export const APP_NAME = 'Port de Québec'
export const APP_SEPARATOR = '-'

export const REFRESH_RATE = 3 * 60 * 1000

export const DATE_FORMAT_API = 'YYYY-MM-DD HH:mm:ss'
export const DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm'
export const DATE_LONG_FORMAT_EN = 'dddd, MMMM Do YYYY'
export const DATE_LONG_FORMAT_FR = 'dddd Do MMMM YYYY'

export const FORMAT_LONG_DATE = (d, time = true) => {
    const TIME = ', h:mm:ss A'
    if (moment.locale() === 'fr') return moment(d).format(DATE_LONG_FORMAT_FR + (time ? TIME : ''))
    return moment(d).format(DATE_LONG_FORMAT_EN + (time ? TIME : ''))
}
