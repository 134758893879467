import { Service } from 'services'
import { Icon } from 'components'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { DATE_TIME_FORMAT } from 'constants/app'

export const GraphActions = {
    analysis: {
        handleAreaClick: updateAnalysisStartManoeuver,
        getTideAtHour: findTideLevelAtHour,
    },
}

async function updateAnalysisStartManoeuver(alias, range, range_shoal) {
    if (!alias || !range || !Array.isArray(range) || range.length < 1) return
    const start = range[0]
    const end = range[range.length - 1]

    const start_shoal = range_shoal[0] ? range_shoal[0].date : null
    const end_shoal = range_shoal[range_shoal.length - 1]
        ? range_shoal[range_shoal.length - 1].date
        : null

    const analysis = await Service.update.analysis({
        alias,
        end_manoeuver_hour: end.date,
        start_manoeuver_hour: start.date,
        end_manoeuver_hour_pilot: end_shoal,
        start_manoeuver_hour_pilot: start_shoal,
    })
    return analysis
}

function findTideLevelAtHour(hour, tides) {
    const diff = t => Math.abs(moment(t.date).diff(moment(hour)))
    let tide = tides.find(t => diff(t) <= 0)
    if (!tide) tides.find(t => diff(t) <= 2)
    if (!tide) tides.find(t => diff(t) <= 5)
    if (!tide) tides.find(t => diff(t) <= 15)

    const lowest_tide = tide?.['wlf-spine'] <= tide?.wlp ? tide?.['wlf-spine'] : tide?.wlp
    return lowest_tide || null
}

export function Overlays(props) {
    const { t } = useTranslation()
    const { loading, error } = props

    return (
        <>
            {loading && (
                <div className='overlay loading'>
                    <Icon>loading</Icon>
                </div>
            )}
            {error && !loading && (
                <div className='overlay error'>
                    <Icon>error</Icon>
                    <h3>{t('general.feedback.error.title')}</h3>
                    <p>{t('general.feedback.error.description')}</p>
                </div>
            )}
        </>
    )
}

export function LastUpdated(props) {
    const { t } = useTranslation()
    const { date } = props

    return (
        <small className='last-updated'>
            {t('general.last_updated')} :<b>{date ? moment(date).format(DATE_TIME_FORMAT) : '—'}</b>
        </small>
    )
}
