import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'

import { Header, Icon } from 'components'

/**
 * Component : ErrorBoundary
 * ---
 * Handles the errors in the Portal.
 * Gives a last-second catching control to the app.
 * Prevent crashes for the user.
 */

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)

        // We want this page to hide when the user then navigates.
        const { history } = this.props
        history.listen((location, action) => {
            if (this.state.hasError) this.setState({ hasError: false })
        })

        this.state = { hasError: false }
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true })
    }

    render() {
        const { t } = this.props

        if (this.state.hasError) {
            return (
                <Fragment>
                    <div className='error-display'>
                        <Header homeLink adminLink />
                        <div className='error-content'>
                            <Icon>error</Icon>
                            <h2>{t('error_boundary.title')}</h2>
                            <p>{t('error_boundary.descr')}</p>
                        </div>
                    </div>
                </Fragment>
            )
        }

        return this.props.children
    }
}

export default withRouter(withTranslation('translation')(ErrorBoundary))
