import { useState, useEffect } from 'react'
import { ADMIN_PWD } from 'constants/admin'
import { Form, Input, Button } from 'antd'
import { formRules } from 'utils'
import jwt from 'jsonwebtoken'

const crypto = require('crypto')
const SESS_KEY = 'pq-04h11-token'
const SESS_SECRET = '1nfJ89ox01kpo999jO0Ojam3njk'
export function useAuth() {
    const getToken = () => {
        const token = JSON.parse(sessionStorage.getItem(SESS_KEY))
        return token
    }

    const emitToken = () => {
        const t = jwt.sign({ data: 'logged' }, SESS_SECRET, { expiresIn: '1h' })
        sessionStorage.setItem(SESS_KEY, JSON.stringify(t))
        return t
    }

    const validateToken = t => {
        try {
            jwt.verify(t, SESS_SECRET)
            return true
        } catch {
            return false
        }
    }

    const [token, setToken] = useState(getToken())
    const [isAuthentified, setIsAuthentified] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        if (validateToken(token)) {
            setIsAuthentified(true)
        } else {
            setToken(null)
            setIsAuthentified(false)
        }
    }, [])

    const authentify = async values => {
        try {
            const { pwd } = values
            const pwd_hash = hashPassword(pwd)
            if (pwd_hash !== ADMIN_PWD) {
                setIsAuthentified(false)
                setToken(null)
                setError('general.validation.bad_pwd')
                return
            }

            setIsAuthentified(true)
            setToken(emitToken())
        } catch (e) {
            setIsAuthentified(false)
            setToken(null)
            setError(e.toString())
        }
    }

    const renderAuthRequest = t => {
        const rules = formRules(t)

        return (
            <div className='login-wrap'>
                <h2>{t('admin_page.auth_title')}</h2>
                <p>{t('admin_page.auth_descr')}</p>
                <Form name='confirm-pwd' className='form-wrap' onFinish={authentify}>
                    <Form.Item name='pwd' rules={[rules.required]}>
                        <Input.Password placeholder={t('general.password')} />
                    </Form.Item>
                    <div className='buttons'>
                        <Button type='primary' htmlType='submit'>
                            {t('general.actions.login')}
                        </Button>
                    </div>
                    {error ? <span className='error'>{t(error)}</span> : <></>}
                </Form>
            </div>
        )
    }

    return { isAuthentified, renderAuthRequest }
}

function hashPassword(password) {
    return crypto.createHash('sha256').update(password).digest('hex')
}
