import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Service } from 'services'
import { Button, Form, Input, InputNumber, Select, DatePicker, message } from 'antd'
import moment from 'moment'
import { formRules } from 'utils'

const calculOptions = [
    {
        id: 'latest_time',
        label: 'dock_page.latest_time',
    },
    {
        id: 'departure_range',
        label: 'dock_page.departure_range',
    },
]

const AnalysisForm = props => {
    const { create, baseValues, alias, title, onSubmit } = props
    const { t } = useTranslation()
    const [docks, setDocks] = useState([])
    const [formValues, setFormValues] = useState({})

    const [form] = Form.useForm()
    const rules = formRules(t)

    useEffect(() => {
        const fetchDocks = async () => {
            try {
                const docks = await Service.list.docks()
                setDocks(docks)
            } catch (e) {
                setDocks([])
            }
        }

        fetchDocks()
    }, [])

    useEffect(() => {
        if (!baseValues) return
        const values = { ...baseValues }
        values.date = moment(values.date)
        form.setFieldsValue(values)

        if (values.dock_shoal) setFormValues({ dock_shoal: values.dock_shoal })
    }, [baseValues])

    const handleSubmit = () => {
        form.validateFields()
            .then(async values => {
                let a
                if (create) {
                    a = await Service.create.analysis(values)
                    message.success(t('general.feedback.create.title'))
                } else {
                    a = await Service.update.analysis({ alias, ...values })
                    message.success(t('general.feedback.update.title'))
                }

                form.resetFields()
                if (onSubmit) onSubmit(a)
            })
            .catch(err => {
                console.log(err)
                message.error(t('general.feedback.error.title'))
            })
    }

    const handleDockChange = dockId => {
        form.setFieldsValue({
            dock_depth: docks.find(d => +d.id === +dockId)?.depth,
            dock_shoal: docks.find(d => +d.id === +dockId)?.shoal,
            dock_calculation_method: docks.find(d => +d.id === +dockId)?.calculation_method,
        })
        setFormValues({
            dock_shoal: docks.find(d => +d.id === +dockId)?.shoal,
        })
    }

    const onFormValuesChange = (changedValues, allValues) => {
        setFormValues(allValues)
    }

    return (
        <article className={create ? 'analysis-form new' : 'analysis-form'}>
            <Form
                form={form}
                name='analysis'
                onFinish={handleSubmit}
                layout='vertical'
                onValuesChange={onFormValuesChange}
                initialValues={{
                    tolerance: 0.75,
                    date: moment(),
                }}
            >
                {title && <h3>{title}</h3>}
                <div className='fields'>
                    <Form.Item
                        name='boat_name'
                        label={t('general.boat_name')}
                        rules={[rules.required]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name='boat_draft'
                        label={t('general.boat_draft_start')}
                        rules={[rules.required]}
                    >
                        <InputNumber step={0.1} min={0} max={30} decimalSeparator=',' />
                    </Form.Item>

                    <Form.Item name='date' label={t('general.date')} rules={[rules.required]}>
                        <DatePicker />
                    </Form.Item>

                    <Form.Item
                        name='dock_id'
                        label={t('general.dock_num')}
                        rules={[rules.required]}
                    >
                        <Select onChange={handleDockChange}>
                            {docks
                                .filter(dock => dock.status === 'active')
                                .map(dock => (
                                    <Select.Option key={dock.id} value={dock.id}>
                                        {dock.label}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name='dock_depth'
                        label={t('general.dock_depth')}
                        rules={[rules.required]}
                    >
                        <InputNumber step={0.1} min={0} max={30} decimalSeparator=',' />
                    </Form.Item>

                    <Form.Item
                        name='dock_shoal'
                        label={t('general.dock_shoal')}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    const shoalValue = parseFloat(value)
                                    const depthValue = parseFloat(getFieldValue('dock_depth'))
                                    if (depthValue > shoalValue || value === null) {
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject(
                                            new Error(t('dock_page.shoal_error_message'))
                                        )
                                    }
                                },
                            }),
                        ]}
                    >
                        <InputNumber step={0.1} min={0} max={30} decimalSeparator=',' />
                    </Form.Item>

                    <Form.Item
                        name='dock_calculation_method'
                        label={t('dock_page.departure')}
                        rules={[rules.required]}
                    >
                        <Select>
                            {calculOptions.map(calcul => (
                                <Select.Option key={calcul.id} value={calcul.id}>
                                    {t(calcul.label)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name='tolerance'
                        label={t('general.tolerance')}
                        rules={[rules.required]}
                    >
                        <InputNumber step={0.25} min={0} max={30} decimalSeparator=',' />
                    </Form.Item>
                </div>

                <div className='buttons'>
                    <Button type='primary' htmlType='submit'>
                        {t('general.actions.submit')}
                    </Button>
                </div>
            </Form>
        </article>
    )
}

export default AnalysisForm
