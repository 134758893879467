import logo from 'assets/images/logos/PQ_RGB.png'
import { Link } from 'react-router-dom'

function Logo(props) {
    return (
        <Link to='/' className='logo'>
            <img src={logo} alt='PQ Logo' />
        </Link>
    )
}

export default Logo
