import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Table } from 'components'
import { TIDES_TABLE_COLUMNS } from 'constants/tables'
import moment from 'moment'
import { Overlays } from 'utils'

function TidesTable(props) {
    const { t } = useTranslation()
    const { data: raw, boatDraft, highest, loading, error } = props

    const [data, setData] = useState([])

    const format = async raw => {
        if (!raw) return []

        const grouped = {}
        raw.forEach(d => {
            const date = moment(d.date).toString()
            if (!grouped[date]) grouped[date] = {}

            d['wlo'] = +d['wlo']
            d['wlp'] = +d['wlp']
            d['wlf-spine'] = +d['wlf-spine']

            if (d['wlo'] !== 0) grouped[date]['wlo'] = d['wlo']
            if (d['wlp'] !== 0) grouped[date]['wlp'] = d['wlp']
            if (d['wlf-spine'] !== 0) grouped[date]['wlf'] = d['wlf-spine']

            if (boatDraft && (d['wlo'] || d['wlf-spine'])) {
                const tide = d.wlo !== 0 ? d.wlo : d.wlp >= d['wlf-spine'] ? d['wlf-spine'] : d.wlp
                const draft = tide - boatDraft
                const clearance = props.dockDepth ? +props.dockDepth + draft : null
                grouped[date]['draft'] = draft
                grouped[date]['clearance'] = clearance
            }
        })

        const data = []
        for (const date in grouped)
            data.push({
                date: moment(date),
                ...grouped[date],
            })

        return data
    }

    useEffect(async () => {
        setData(await format(raw))
    }, [raw])

    const cols = TIDES_TABLE_COLUMNS(t)
    if (highest) {
        cols.pop()
        cols.pop()
    }

    return (
        <div className='tides-table-wrap'>
            <Table title={t('overview_page.panel_tides_levels_title')} columns={cols} data={data} />
            <Overlays loading={loading} error={error} />
        </div>
    )
}

export default TidesTable
