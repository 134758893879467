import { Helmet, Logo, Icon } from 'components'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import moment from 'moment'
import 'moment/locale/fr'

function Header(props) {
    const { title, subtitle, pretitle, homeLink, adminLink, dockLink, dashLink, hideNav } = props
    const { t, i18n } = useTranslation()

    const [wider, setWider] = useState(sessionStorage.getItem('wider') !== 'false')
    const toggleWider = () => {
        sessionStorage.setItem('wider', !wider)
        setWider(!wider)
    }

    const toggleLng = () => {
        const lng = i18n.language === 'fr' ? 'en' : 'fr'
        i18n.changeLanguage(lng)
        moment.locale(lng)
    }

    return (
        <header className='page-header'>
            <Helmet>
                <body data-wider={wider} />
            </Helmet>

            <nav style={{ display: hideNav === true ? 'none' : 'flex' }}>
                <Logo />
                <ul>
                    {homeLink ? (
                        <li>
                            <Link to='/'>
                                <Icon>home</Icon>
                                <i>{t('navigation.overview')}</i>
                            </Link>
                        </li>
                    ) : (
                        <></>
                    )}
                    {dockLink ? (
                        <li>
                            <Link to='/admin/dock'>
                                <Icon>boat</Icon>
                                <i>{t('navigation.dock')}</i>
                            </Link>
                        </li>
                    ) : (
                        <></>
                    )}
                    {adminLink ? (
                        <li>
                            <Link to='/admin'>
                                <Icon>user</Icon>
                                <i>{t('navigation.admin')}</i>
                            </Link>
                        </li>
                    ) : (
                        <></>
                    )}
                    {dashLink ? (
                        <li>
                            <Link to='/admin'>
                                <Icon>user</Icon>
                                <i>{t('navigation.dash')}</i>
                            </Link>
                        </li>
                    ) : (
                        <></>
                    )}
                    <li>
                        <a onClick={toggleLng}>
                            <Icon>trad</Icon>
                            {t('navigation.language')}
                        </a>
                    </li>
                    <li>
                        <Button
                            onClick={toggleWider}
                            icon={<Icon>{wider ? '!fullscreen' : 'fullscreen'}</Icon>}
                        />
                    </li>
                </ul>
            </nav>

            <div className='titles'>
                {pretitle && <small>{pretitle}</small>}
                <h1>{title}</h1>
                <p>{subtitle}</p>
                {title && <span className={'separation wave'} />}
            </div>
        </header>
    )
}

export default Header
