import { API_URL } from 'constants/api'

/**
 * Utils : API
 * ---
 * Functions to help the interractions with the API.
 */

/**
 * Handles the API's querying.
 * Fetch, then if error detected in server's response, format it and handle it correctly.
 * @params  { String }  route   the path to query.
 * @params  { String }  params  the params to supply to fetch API.
 * @params  { Function }    callback    a callback to format the server's response
 */
export async function queryAPI(route, params, logIfError = false, callback = result => result) {
    if (!route || !params) return {}
    let response, result

    // Fetching + decoding
    try {
        response = await fetch(`${API_URL}/${route}`, params)
        result = await response.json()
    } catch (e) {
        throw {
            status: 404,
            error: e,
            error_id: 'api_down',
        }
    }

    // Error scouting
    if (!result || 'error' in result) {
        // fixme: condition ambigu; si result est undefined on accède quand même result.error ?
        const e = {
            status: response.status,
            error: result.error,
            error_id: result.error_id,
        }

        throw e
    }

    // Return (formatted) value
    return callback(result)
}

export function serialize(obj) {
    let str = []
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            if (Array.isArray(obj[p])) {
                obj[p].forEach(val =>
                    str.push(encodeURIComponent(p) + '[]=' + encodeURIComponent(val))
                )
            } else {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
            }
        }
    }

    return str.join('&')
}
