import { useTranslation } from 'react-i18next'
import { Modal, Button, Form, Input, InputNumber, Select, message } from 'antd'
import { formRules } from 'utils'
import { Service } from 'services'

const calculOptions = [
    {
        id: 'latest_time',
        label: 'dock_page.latest_time',
    },
    {
        id: 'departure_range',
        label: 'dock_page.departure_range',
    },
]

const CreateModal = props => {
    const { onSubmit, title, visible, onClose } = props
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const rules = formRules(t)

    const handleFormSubmit = () => {
        form.validateFields()
            .then(values => {
                const { key, ...updatedValues } = values

                return Service.create.dock(updatedValues)
            })
            .then(result => {
                message.success(t('general.feedback.update.title'))
                onSubmit(result)
                onClose()
            })
            .catch(err => {
                console.log(err)
                message.error(t('general.feedback.error.title'))
            })
    }

    return (
        <Modal
            title={title || t('dock_page.create_title')}
            visible={visible}
            onCancel={onClose}
            width='50%'
            footer={[
                <Button key='close' type='primary' onClick={onClose}>
                    {t('general.actions.cancel')}
                </Button>,
                <Button key='submit' type='primary' htmlType='submit' form='dockForm'>
                    {t('general.actions.submit')}
                </Button>,
            ]}
        >
            <article className='analysis-form'>
                <Form
                    form={form}
                    id='dockForm'
                    layout='vertical'
                    onFinish={handleFormSubmit}
                    initialValues={{
                        calculation_method: 'latest_time',
                    }}
                >
                    {title && <h3>{title}</h3>}
                    <div className='fields'>
                        <Form.Item
                            name='label'
                            label={t('general.dock_num')}
                            rules={[rules.required]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            name='depth'
                            label={t('dock_page.depth')}
                            rules={[rules.required]}
                        >
                            <InputNumber step={0.1} min={0} max={30} decimalSeparator=',' />
                        </Form.Item>

                        <Form.Item
                            name='shoal'
                            label={t('dock_page.shoal')}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const shoalValue = parseFloat(value)
                                        const depthValue = parseFloat(getFieldValue('depth'))
                                        if (depthValue > shoalValue || value === undefined) {
                                            return Promise.resolve()
                                        } else {
                                            return Promise.reject(
                                                new Error(t('dock_page.shoal_error_message'))
                                            )
                                        }
                                    },
                                }),
                            ]}
                            validateTrigger='onSubmit'
                        >
                            <InputNumber step={0.1} min={0} max={30} decimalSeparator=',' />
                        </Form.Item>

                        <Form.Item
                            name='calculation_method'
                            label={t('dock_page.departure')}
                            rules={[rules.required]}
                        >
                            <Select>
                                {calculOptions.map(calcul => (
                                    <Select.Option key={calcul.id} value={calcul.id}>
                                        {t(calcul.label)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name='email_bccs'
                            label={t('dock_page.CC_list')}
                            rules={[
                                {
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.resolve()
                                        }
                                        const emails = value.split(';').map(email => email.trim())
                                        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                                        // Validate each email
                                        const allEmailsValid = emails.every(email =>
                                            emailRegex.test(email)
                                        )
                                        if (!allEmailsValid) {
                                            return Promise.reject(
                                                new Error(t('dock_page.invalid_email_list'))
                                            )
                                        }

                                        // Check for duplicates
                                        const uniqueEmails = new Set(emails)
                                        if (uniqueEmails.size !== emails.length) {
                                            return Promise.reject(
                                                new Error(t('dock_page.duplicate_email_error'))
                                            )
                                        }

                                        return Promise.resolve()
                                    },
                                },
                            ]}
                            validateTrigger='onSubmit'
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </Form>
            </article>
        </Modal>
    )
}

export default CreateModal
