import React from 'react'
import { Logo, Icon } from 'components'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'

import { Service } from 'services'
import moment from 'moment'
import { DATE_TIME_FORMAT } from 'constants/app'

function Footer(props) {
    const { t } = useTranslation()

    const [statusModalOpened, setStatusModalOpened] = React.useState(false)
    const [apiStatus, setApiStatus] = React.useState({
        portal: { status: true },
        loading: false,
    })

    const mounted = React.useRef(true)

    React.useEffect(function () {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    const fetchStatus = async () => {
        try {
            const status = await Service.get.status()
            if (!mounted.current) return
            setApiStatus({ ...status, portal: { status: true }, loading: false })
        } catch (e) {
            if (!mounted.current) return
            console.log(e)
            setApiStatus({ portal: { status: true }, loading: false })
        }
    }

    React.useEffect(
        function () {
            if (!statusModalOpened) return
            fetchStatus()
        },
        [statusModalOpened]
    )

    const toggleStatusModalOpened = () => setStatusModalOpened(!statusModalOpened)

    // Util function to render the status info of a "section".
    // Will look for [key] in this.state.status
    const renderStatus = key => {
        if (apiStatus.loading)
            return (
                <span className='grey'>
                    <Icon>loading</Icon>
                    {t('general.possible_status.loading')}
                </span>
            )

        const value = key in apiStatus ? apiStatus[key].status : false
        return (
            <span className={value ? 'green' : 'red'}>
                <Icon>circle</Icon>
                {t(`general.possible_status.${value ? 'up' : 'down'}`)}
            </span>
        )
    }

    return (
        <footer className='page-footer'>
            <div className='logo-wrap'>
                <Logo global />
                <div className='links'>
                    <a href='https://www.portquebec.ca/' target='_blank' rel='noreferrer'>
                        <Icon>link</Icon>
                        {t('footer.global_site')}
                    </a>
                    <a onClick={toggleStatusModalOpened}>
                        <Icon>status</Icon>
                        {t('footer.services_status')}
                    </a>
                </div>
            </div>

            <div>
                <p>{t('footer.address_1')}</p>
                <p>{t('footer.address_2')}</p>
                <p>{t('footer.address_3')}</p>
                <p>{t('footer.address_4')}</p>
            </div>

            <div>
                <p>{t('general.phone')} : (418) 648-3640</p>
                <p>{t('general.fax')} : (418) 648-4160</p>
                <a href='mailto:capitainerie@portquebec.ca' target='_blank' rel='noreferrer'>
                    capitainerie@portquebec.ca
                </a>
            </div>

            {/* Status Modal */}
            <Modal
                visible={statusModalOpened}
                onCancel={toggleStatusModalOpened}
                title={t('footer.services_status')}
                footer={null}
                className='status-modal'
            >
                <p>
                    <label>{t('general.portal')}</label>
                    {renderStatus('portal')}
                </p>
                <p>
                    <label>{t('general.api')}</label>
                    {renderStatus('back')}
                </p>
                <p>
                    <label>{t('general.api_tides')}</label>
                    {renderStatus('api')}
                </p>

                <small>
                    {t('general.last_data_update')} :
                    <b>
                        {apiStatus?.api?.last_update
                            ? moment(apiStatus?.api?.last_update.replace(' UTC', 'Z')).format(
                                  DATE_TIME_FORMAT
                              )
                            : '?'}
                    </b>
                </small>
            </Modal>
        </footer>
    )
}

export default Footer
