import moment from 'moment'
import { DATE_TIME_FORMAT } from 'constants/app'
import { Link } from 'react-router-dom'
import { Icon } from 'components'
import { handleAnalysisMailing } from 'utils'
import { Tooltip } from 'antd'

export const ANALYSIS_LIST_TABLE_COLUMNS = t => [
    {
        title: t('general.dock'),
        dataIndex: 'dock',
        key: 'dock',
        sorter: (a, b) => a.dock.label - b.dock.label,
        sortDirections: ['descend', 'ascend'],
        render: dock => {
            const isDisabled = dock.status === 'disabled'
            const circleClassName = isDisabled ? 'disabled-circle' : 'circle'

            return <span className={circleClassName}>{dock.label.replace('Quai ', '')}</span>
        },
        export: dock => dock.label,
    },
    {
        title: t('general.date'),
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
        sortDirections: ['descend', 'ascend'],
        render: date => moment(date).format(DATE_TIME_FORMAT),
    },
    {
        title: t('general.boat_name'),
        dataIndex: 'boat_name',
        key: 'boat_name',
        sorter: (a, b) => a.boat_name - b.boat_name,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: t('general.boat_draft'),
        dataIndex: 'boat_draft',
        key: 'boat_draft',
        sorter: (a, b) => a.boat_draft - b.boat_draft,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: t('general.tolerance'),
        dataIndex: 'tolerance',
        key: 'tolerance',
        sorter: (a, b) => a.tolerance - b.tolerance,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: t('general.start_hour'),
        dataIndex: 'end_manoeuver_hour',
        key: 'end_manoeuver_hour',
        sorter: (a, b) => new Date(a.end_manoeuver_hour) - new Date(b.end_manoeuver_hour),
        sortDirections: ['descend', 'ascend'],
        render: date => (date ? moment(date).format(DATE_TIME_FORMAT) : ''),
    },
    {
        title: 'Action',
        key: 'actions',
        export: false,
        render: (text, analysis) => {
            return (
                <>
                    <Link to={'/' + analysis.alias || ''}>
                        <Icon>details</Icon>
                        {t('general.details')}
                    </Link>
                </>
            )
        },
    },
]

const EmailTooltipContent = ({ emails }) => (
    <span dangerouslySetInnerHTML={{ __html: emails.split(';').join(';<br>') }} />
)

export const TIDES_TABLE_COLUMNS = t => [
    {
        title: t('general.date'),
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
        sortDirections: ['descend', 'ascend'],
        render: date => moment(date).format(DATE_TIME_FORMAT),
    },
    {
        title: t('general.wlo'),
        dataIndex: 'wlo',
        key: 'wlo',
        sorter: (a, b) => a.wlo - b.wlo,
        sortDirections: ['descend', 'ascend'],
        render: n => (isNaN(n) ? '' : parseFloat(n).toFixed(2)),
    },
    {
        title: t('general.wlf'),
        dataIndex: 'wlf',
        key: 'wlf',
        sorter: (a, b) => a.wlf - b.wlf,
        sortDirections: ['descend', 'ascend'],
        render: n => (isNaN(n) ? '' : parseFloat(n).toFixed(2)),
    },
    {
        title: t('general.wlp'),
        dataIndex: 'wlp',
        key: 'wlp',
        sorter: (a, b) => (a.wlp || 0) - (b.wlp || 0),
        sortDirections: ['descend', 'ascend'],
        render: n => (isNaN(n) ? '' : parseFloat(n).toFixed(2)),
    },
    {
        title: t('general.security_clearance'),
        dataIndex: 'clearance',
        key: 'clearance',
        sorter: (a, b) => a.clearance - b.clearance,
        sortDirections: ['descend', 'ascend'],
        render: n => (isNaN(n) ? '' : parseFloat(n).toFixed(2)),
    },
]

export const DOCK_LIST_TABLE_COLUMNS = (t, handleOpenModal, handleDisableButton) => [
    {
        title: t('general.dock'),
        dataIndex: 'label',
        key: 'label',
        sorter: (a, b) => a.label - b.label,
        sortDirections: ['descend', 'ascend'],
        render: (label, record) => {
            const isDisabled = record.status === 'disabled'
            const circleClassName = isDisabled ? 'disabled-circle' : 'circle'

            return <span className={circleClassName}>{label.replace('Quai ', '')}</span>
        },
        export: label => label,
    },
    {
        title: t('dock_page.depth'),
        dataIndex: 'depth',
        key: 'depth',
        sorter: (a, b) => a.depth - b.depth,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: t('dock_page.shoal'),
        dataIndex: 'shoal',
        key: 'shoal',
        sorter: (a, b) => a.shoal - b.shoal,
        sortDirections: ['descend', 'ascend'],
    },
    {
        title: t('dock_page.departure'),
        dataIndex: 'calculation_method',
        key: 'calculation_method',
        sorter: (a, b) => a.calculation_method - b.calculation_method,
        sortDirections: ['descend', 'ascend'],
        render: text => t(`dock_page.${text}`),
    },
    {
        title: t('dock_page.CC_list'),
        dataIndex: 'email_bccs',
        key: 'email_bccs',
        render: text => (
            <Tooltip placement='topLeft' title={<EmailTooltipContent emails={text} />}>
                <div
                    style={{
                        maxWidth: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {text}
                </div>
            </Tooltip>
        ),
    },
    {
        title: 'Actions',
        key: 'actions',
        export: false,
        render: (text, record) => {
            const isInactive = record.status === 'disabled'

            return (
                <>
                    <a onClick={() => !isInactive && handleOpenModal(record)} disabled={isInactive}>
                        <Icon>edit</Icon>
                        {t('dock_page.edit')}
                    </a>
                    <a onClick={() => handleDisableButton(record)}>
                        <Icon>{isInactive ? 'add' : 'check'}</Icon>
                        {isInactive ? t('dock_page.activate') : t('dock_page.disable')}
                    </a>
                </>
            )
        },
        sorter: (a, b) => {
            const statusComparison = a.status.localeCompare(b.status)

            if (statusComparison !== 0) {
                return statusComparison
            }
            return a.label - b.label
        },
    },
]
