import { DATE_FORMAT_API } from 'constants/app'
import { queryAPI, serialize } from 'utils'
import moment from 'moment'

export const Service = {
    list: {
        analysis: listAnalysis,
        levels: listTideLevels,
        highestTides: listHighestTides,
        docks: listDocks,
    },
    get: {
        analysis: getAnalysis,
        status: getStatus,
        dock: getDock,
    },
    create: {
        analysis: createAnalysis,
        dock: createDock,
    },
    update: {
        analysis: updateAnalysis,
        dock: updateDock,
    },
    send: {
        mail: sendMail,
    },
}

const GET_PARAMS = {
    method: 'GET',
}

async function listAnalysis() {
    return await queryAPI(`analyzes`, GET_PARAMS)
}

async function listTideLevels(baseDate, dateOffset) {
    if (!baseDate) baseDate = moment().utc()
    if (!Array.isArray(dateOffset) || dateOffset.length !== 2) dateOffset = [-12, 24]

    const filters = {
        date: [
            moment(baseDate).utc().add(dateOffset[0], 'hour').format(DATE_FORMAT_API),
            moment(baseDate).utc().add(dateOffset[1], 'hour').format(DATE_FORMAT_API),
        ],
        for_captain: 0,
    }

    return await queryAPI(`api_datas/?${serialize(filters)}`, GET_PARAMS)
}

async function listDocks() {
    return await queryAPI(`docks`, GET_PARAMS)
}

async function getStatus() {
    return await queryAPI(`status`, GET_PARAMS)
}

async function getAnalysis(alias) {
    return await queryAPI(`analyzes/${alias}`, GET_PARAMS)
}

async function getDock(dockId) {
    return await queryAPI(`docks/${dockId}`, GET_PARAMS)
}

async function createAnalysis(analysis) {
    const tides = await listHighestTides()
    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            analyze: {
                ...analysis,
                highest_tides: JSON.stringify(tides.highest_tides),
            },
        }),
    }

    return await queryAPI(`analyzes`, params)
}

async function updateAnalysis(analysis) {
    const params = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            analyze: analysis,
        }),
    }

    return await queryAPI(`analyzes/${analysis.alias}`, params)
}

async function createDock(dock) {
    const params = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            dock: dock,
        }),
    }

    return await queryAPI(`docks`, params)
}

async function updateDock(dock) {
    const params = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            dock: dock,
        }),
    }

    return await queryAPI(`docks/${dock.id}`, params)
}

async function listHighestTides() {
    return await queryAPI(`analyzes/new`, GET_PARAMS)
}

async function sendMail(dest, id) {
    const params = {
        method: 'POST',
    }

    return await queryAPI(`analyzes/${id}/mail?receiver=${dest}`, params)
}
