import ReactDOMServer from 'react-dom/server'
import moment from 'moment'
import { DATE_TIME_FORMAT } from 'constants/app'
import { transformTimeStep, GraphActions } from 'utils'
import { PROD_URL } from 'constants/export'

const formatNum = num => {
    if (!num) return (0).toFixed(2)
    return parseFloat(num).toFixed(2)
}

export const HTMLTemplate = (a, levelsAroundManoeuver_start, levelsAroundManoeuver_end, image) => {
    const tides = JSON.parse(a.highest_tides).filter(t => t != null)
    const tide_at_departure_start = GraphActions.analysis.getTideAtHour(
        a.start_manoeuver_hour,
        levelsAroundManoeuver_start
    )
    const tide_at_departure_pilot_start = GraphActions.analysis.getTideAtHour(
        a.start_manoeuver_hour_pilot,
        levelsAroundManoeuver_start
    )
    const tide_at_departure_end = GraphActions.analysis.getTideAtHour(
        a.end_manoeuver_hour,
        levelsAroundManoeuver_end
    )
    const tide_at_departure_pilot_end = GraphActions.analysis.getTideAtHour(
        a.end_manoeuver_hour_pilot,
        levelsAroundManoeuver_end
    )
    const min_tide_depth = formatNum(
        +a.boat_draft + +a.tolerance - +(a.dock_depth || a?.dock?.depth || 0)
    )
    const min_tide_shoal = formatNum(
        +a.boat_draft + +a.tolerance - +(a.dock_shoal || a?.dock?.shoal || 0)
    )
    const hasShoal = a.dock_shoal !== undefined && a.dock_shoal !== 0 && a.dock_shoal !== null
    const min_tide = min_tide_depth > 0 ? min_tide_depth : '-'
    const min_tide_pilot = min_tide_shoal > 0 ? min_tide_shoal : '-'

    const calcul_type =
        a.dock_calculation_method === 'departure_range'
            ? 'Fenêtre de départ / Departure window'
            : 'Heure maximale / Maximum depature time'

    levelsAroundManoeuver_end = transformTimeStep(levelsAroundManoeuver_end, 15)

    return ReactDOMServer.renderToString(
        <html>
            <head>
                <meta charSet='UTF-8' />
            </head>
            <body>
                <table cellspacing='10'>
                    <tbody>
                        <p>
                            Bonjour, <br />
                            <br />
                            Voici les informations concernant le départ du navire{' '}
                            <b>{a.boat_name}</b> au quai <b>{a?.dock?.label || '-'}</b>.<br />
                            Here is the information regarding the departure of the{' '}
                            <b>{a.boat_name}</b> at berth <b>{a?.dock?.label || '-'}</b>.
                        </p>

                        <h2>Informations générales / General information :</h2>
                        <ul>
                            <li>
                                Nom du navire / Vessel name : <b>{a.boat_name}</b>
                            </li>
                            <li>
                                Quai / Berth : <b>{a?.dock?.label || '-'}</b>
                            </li>
                            <li>
                                Date de création de l'analyse / Analysis date :{' '}
                                <b>{moment(a.date).format(DATE_TIME_FORMAT)}</b>
                            </li>
                            <li>
                                Type de calcul de départ / Departure calculation mode :{' '}
                                <b>{calcul_type}</b>
                            </li>
                            {a.alias ? (
                                <li>
                                    Lien vers l'analyse / Link to analysis :{' '}
                                    <b>
                                        <a href={`${decodeURIComponent(PROD_URL)}${a.alias}`}>
                                            ici / here
                                        </a>
                                    </b>
                                </li>
                            ) : (
                                <></>
                            )}
                        </ul>

                        <h2>Calcul de dégagement / Clearance calculation :</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td width={60}>{formatNum(a.boat_draft)}</td>
                                    <td width={80}>M</td>
                                    <td>
                                        Tirant d'eau du navire au départ
                                        <br />
                                        Vessel Draft at Departure
                                    </td>
                                </tr>
                                <tr>
                                    <td>{formatNum(a.tolerance)}</td>
                                    <td>M</td>
                                    <td>
                                        Dégagement de sécurité sous quille (00.75m été / 01.00m
                                        hiver) <br />
                                        Under Keel Clearance (0.75m summer / 1m winter)
                                    </td>
                                </tr>
                                <tr>
                                    <td>{formatNum(+a.boat_draft + +a.tolerance)}</td>
                                    <td>M</td>
                                    <td>
                                        Total du tirant d'eau plus le dégagement de sécurité sous
                                        quille <br />
                                        Total draft plus safety clearance under keel
                                    </td>
                                </tr>
                                <tr>
                                    <td>{formatNum(a.dock_depth) || '?'}</td>
                                    <td>M</td>
                                    <td>
                                        Profondeur au Quai {a?.dock?.label || '-'} <br />
                                        Depth at Berth {a?.dock?.label || '-'}
                                    </td>
                                </tr>
                                {hasShoal && (
                                    <tr>
                                        <td>{formatNum(a.dock_shoal) || '?'}</td>
                                        <td>M</td>
                                        <td>
                                            Haut-fond au Quai {a?.dock?.label || '-'} <br />
                                            Shallow spot at Berth {a?.dock?.label || '-'}
                                        </td>
                                    </tr>
                                )}
                                {hasShoal && moment(a.end_manoeuver_hour_pilot).isValid() && (
                                    <tr>
                                        <td>
                                            <b>{min_tide_pilot}</b>
                                        </td>
                                        <td>M</td>
                                        <td>
                                            Marée minimum à laquelle le navire doit quitter{' '}
                                            <b>
                                                <u>SANS pilote lamaneur</u>
                                            </b>{' '}
                                            (heure maximale prévue :{' '}
                                            {moment(a.end_manoeuver_hour_pilot).format(
                                                DATE_TIME_FORMAT
                                            )}
                                            ) <br />
                                            Minimal Tide for Vessel departure{' '}
                                            <b>
                                                <u>WITHOUT specialized pilot</u>
                                            </b>{' '}
                                            (Latest time anticipated :{' '}
                                            {moment(a.end_manoeuver_hour_pilot).format(
                                                DATE_TIME_FORMAT
                                            )}
                                            )
                                        </td>
                                    </tr>
                                )}
                                {hasShoal && (
                                    <tr>
                                        <td>
                                            <b>{min_tide}</b>
                                        </td>
                                        <td>M</td>
                                        <td>
                                            Marée minimum à laquelle le navire doit quitter{' '}
                                            <b>
                                                <u>AVEC pilote lamaneur</u>
                                            </b>{' '}
                                            (heure maximale prévue :{' '}
                                            {moment(a.end_manoeuver_hour).format(DATE_TIME_FORMAT)}){' '}
                                            <br />
                                            Minimal Tide for Vessel departure{' '}
                                            <b>
                                                <u>WITH harbour pilot</u>
                                            </b>{' '}
                                            (Latest time anticipated :{' '}
                                            {moment(a.end_manoeuver_hour).format(DATE_TIME_FORMAT)})
                                        </td>
                                    </tr>
                                )}
                                {!hasShoal && (
                                    <tr>
                                        <td>
                                            <b>{min_tide}</b>
                                        </td>
                                        <td>M</td>
                                        <td>
                                            Marée minimum à laquelle le navire doit quitter (heure
                                            maximale prévue :{' '}
                                            {moment(a.end_manoeuver_hour).format(DATE_TIME_FORMAT)}){' '}
                                            <br />
                                            Minimal Tide for Vessel departure (Latest time
                                            anticipated :{' '}
                                            {moment(a.end_manoeuver_hour).format(DATE_TIME_FORMAT)})
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <h2>Résumé / Summary :</h2>
                        <table cellspacing='10'>
                            <tbody>
                                {a.dock_calculation_method === 'departure_range' && !hasShoal && (
                                    <tr>
                                        <td width={140}>
                                            <b>
                                                {moment(a.start_manoeuver_hour).format(
                                                    DATE_TIME_FORMAT
                                                )}
                                            </b>
                                        </td>
                                        <td>
                                            Date et heure de départ minimale (niveau d'eau prévu de{' '}
                                            <b>
                                                {formatNum(tide_at_departure_start || min_tide)} M
                                            </b>
                                            ) <br />
                                            Earliest Date and time for departure (anticipated water
                                            level of{' '}
                                            <b>
                                                {formatNum(tide_at_departure_start || min_tide)} M
                                            </b>
                                            )
                                        </td>
                                    </tr>
                                )}
                                {a.dock_calculation_method === 'departure_range' &&
                                    hasShoal &&
                                    moment(a.end_manoeuver_hour_pilot).isValid() && (
                                        <tr>
                                            <td width={140}>
                                                <b>
                                                    {moment(a.start_manoeuver_hour_pilot).format(
                                                        DATE_TIME_FORMAT
                                                    )}
                                                </b>
                                            </td>
                                            <td>
                                                Date et heure de départ minimale{' '}
                                                <b>
                                                    <u>SANS pilote lamaneur</u>
                                                </b>{' '}
                                                (niveau d'eau prévu de{' '}
                                                <b>
                                                    {formatNum(
                                                        tide_at_departure_pilot_start ||
                                                            min_tide_pilot
                                                    )}{' '}
                                                    M
                                                </b>
                                                ) <br />
                                                Earliest Date and time for departure{' '}
                                                <b>
                                                    <u>WITHOUT harbour pilot</u>
                                                </b>{' '}
                                                (anticipated water level of{' '}
                                                <b>
                                                    {formatNum(
                                                        tide_at_departure_pilot_start ||
                                                            min_tide_pilot
                                                    )}{' '}
                                                    M
                                                </b>
                                                )
                                            </td>
                                        </tr>
                                    )}
                                {hasShoal && moment(a.end_manoeuver_hour_pilot).isValid() && (
                                    <tr>
                                        <td width={140}>
                                            <b>
                                                {moment(a.end_manoeuver_hour_pilot).format(
                                                    DATE_TIME_FORMAT
                                                )}
                                            </b>
                                        </td>
                                        <td>
                                            Date et heure de départ maximale{' '}
                                            <b>
                                                <u>SANS pilote lamaneur</u>
                                            </b>{' '}
                                            (niveau d'eau prévu de{' '}
                                            <b>
                                                {formatNum(
                                                    tide_at_departure_pilot_end || min_tide_pilot
                                                )}{' '}
                                                M
                                            </b>
                                            ) <br />
                                            Latest Date and time for departure{' '}
                                            <b>
                                                <u>WITHOUT harbour pilot</u>
                                            </b>{' '}
                                            (anticipated water level of{' '}
                                            <b>
                                                {formatNum(
                                                    tide_at_departure_pilot_end || min_tide_pilot
                                                )}{' '}
                                                M
                                            </b>
                                            )
                                        </td>
                                    </tr>
                                )}
                                <tr />
                                <tr />
                                {a.dock_calculation_method === 'departure_range' && hasShoal && (
                                    <tr>
                                        <td width={140}>
                                            <b>
                                                {moment(a.start_manoeuver_hour).format(
                                                    DATE_TIME_FORMAT
                                                )}
                                            </b>
                                        </td>
                                        <td>
                                            Date et heure de départ minimale{' '}
                                            <b>
                                                <u>AVEC pilote lamaneur</u>
                                            </b>{' '}
                                            (niveau d'eau prévu de{' '}
                                            <b>
                                                {formatNum(tide_at_departure_start || min_tide)} M
                                            </b>
                                            ) <br />
                                            Earliest Date and time for departure{' '}
                                            <b>
                                                <u>WITH harbour pilot</u>
                                            </b>{' '}
                                            (anticipated water level of{' '}
                                            <b>
                                                {formatNum(tide_at_departure_start || min_tide)} M
                                            </b>
                                            )
                                        </td>
                                    </tr>
                                )}
                                {hasShoal && (
                                    <tr>
                                        <td width={140}>
                                            <b>
                                                {moment(a.end_manoeuver_hour).format(
                                                    DATE_TIME_FORMAT
                                                )}
                                            </b>
                                        </td>
                                        <td>
                                            Date et heure de départ maximale{' '}
                                            <b>
                                                <u>AVEC pilote lamaneur</u>
                                            </b>{' '}
                                            (niveau d'eau prévu de{' '}
                                            <b>{formatNum(tide_at_departure_end || min_tide)} M</b>){' '}
                                            <br />
                                            Latest Date and time for departure{' '}
                                            <b>
                                                <u>WITH harbour pilot</u>
                                            </b>{' '}
                                            (anticipated water level of{' '}
                                            <b>{formatNum(tide_at_departure_end || min_tide)} M</b>)
                                        </td>
                                    </tr>
                                )}
                                {!hasShoal && (
                                    <tr>
                                        <td width={140}>
                                            <b>
                                                {moment(a.end_manoeuver_hour).format(
                                                    DATE_TIME_FORMAT
                                                )}
                                            </b>
                                        </td>
                                        <td>
                                            Date et heure de départ maximale (niveau d'eau prévu de{' '}
                                            <b>{formatNum(tide_at_departure_end || min_tide)} M</b>){' '}
                                            <br />
                                            Latest Date and time for departure (anticipated water
                                            level of{' '}
                                            <b>{formatNum(tide_at_departure_end || min_tide)} M</b>)
                                        </td>
                                    </tr>
                                )}
                                <tr />
                                <tr />
                                {a.dock_calculation_method === 'latest_time' && !hasShoal && (
                                    <tr>
                                        <td width={140}>
                                            {moment(a.end_manoeuver_hour)
                                                .subtract(1, 'hour')
                                                .format(DATE_TIME_FORMAT)}
                                        </td>
                                        <td>
                                            Mobilisation des intervenants <br />
                                            Summoning of stakeholders
                                        </td>
                                    </tr>
                                )}
                                {a.dock_calculation_method === 'latest_time' && hasShoal && (
                                    <tr>
                                        <td width={140}>-</td>
                                        <td>
                                            La mobilisation des intervenants doit être ordonnée une
                                            heure avant l'heure de départ prévue. <br />
                                            The mobilization of participants must be ordered one
                                            hour before the scheduled departure time.
                                        </td>
                                    </tr>
                                )}
                                {a.dock_calculation_method === 'departure_range' && (
                                    <tr>
                                        <td width={140}>-</td>
                                        <td>
                                            La mobilisation des intervenants doit être ordonnée une
                                            heure avant l'heure de départ prévue. <br />
                                            The mobilization of participants must be ordered one
                                            hour before the scheduled departure time.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {image ? (
                            <>
                                <h2>Graphique / Graph :</h2>
                                <img alt='' src={image} />
                            </>
                        ) : (
                            <></>
                        )}
                        <br />
                        <h2>Hautes Marées / High tides :</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th width={140}>Date</th>
                                    <th width={60}>Obs.</th>
                                    <th width={60}>Prév/Prev.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tides.map((t, i) => (
                                    <tr key={i} style={{ textAlign: 'center' }}>
                                        <td width={140}>
                                            {moment(t.date).format(DATE_TIME_FORMAT)}
                                        </td>
                                        <td width={60}>{formatNum(t.wlo)}</td>
                                        <td width={60}>{formatNum(t['wlf-spine'])}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <h2>
                            Marées autour de {moment(a.end_manoeuver_hour).format(DATE_TIME_FORMAT)}{' '}
                            (heure de départ max.) :<br />
                            Tides around {moment(a.end_manoeuver_hour).format(
                                DATE_TIME_FORMAT
                            )}{' '}
                            (Latest departure time) :
                        </h2>
                        <table>
                            <thead>
                                <tr>
                                    <th width={140}>Date</th>
                                    <th width={60}>Obs.</th>
                                    <th width={60}>Prév.</th>
                                    <th width={60}>Préd.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {levelsAroundManoeuver_end.map((t, i) => (
                                    <tr key={i} style={{ textAlign: 'center' }}>
                                        <td width={140}>
                                            {moment(t.date).format(DATE_TIME_FORMAT)}
                                        </td>
                                        <td width={60}>{formatNum(t.wlo)}</td>
                                        <td width={60}>{formatNum(t['wlf-spine'])}</td>
                                        <td width={60}>{formatNum(t.wlp)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </tbody>
                </table>
            </body>
        </html>
    )
}

export const mailtoBody = a => {
    const url = a.alias ? `${decodeURIComponent(PROD_URL)}${a.alias}` : null
    const min_tide = formatNum(
        +a.boat_draft + +a.tolerance - (+a.dock_depth || +a?.dock?.depth || 0)
    )

    return encodeURIComponent(`
    Bonjour,
	Voici les informations concernant le départ du navire ${a.boat_name} au quai ${
        a?.dock?.label || '-'
    }.
    Pour plus de détails, consultez la procédure de départ ainsi que les prévisions des marées en pièces jointes.
	
	Informations générales :
	Date de départ maximale : ${moment(a.end_manoeuver_hour).format(DATE_TIME_FORMAT)}
	Nom du navire : ${a.boat_name}
	Quai : ${a?.dock?.label || '-'}
	Lien vers l'analyse : ${url || '-'}
	
	Calcul de dégagement :
	Tirant d'eau du navire au départ : ${formatNum(a.boat_draft)} m
	Dégagement de securité sous quille (00.75m été / 01.00m hiver) : ${formatNum(a.tolerance)} m
	Total du tirant d'eau plus le dégagement de sécurité sous quille : ${formatNum(
        +a.boat_draft + +a.tolerance
    )} m
	Profondeur du Quai ${a?.dock?.label || '-'} : ${formatNum(a.dock_depth || a?.dock?.depth) || '?'} m
	Marée minimum à laquelle le navire doit quitter : ${min_tide > 0 ? min_tide : '-'} m

    -----------------------------------------------------------------------------------------------------

    Hello,
    Here are the details regarding the departure of the ship ${a.boat_name} at berth ${
        a?.dock?.label || '-'
    }.
    For more details, please refer to the departure procedure and the tide forecasts in the attachments.

    General Information:
    Maximum departure date: ${moment(a.end_manoeuver_hour).format(DATE_TIME_FORMAT)}
    Ship name: ${a.boat_name}
    Berth: ${a?.dock?.label || '-'}
    Link to the analysis: ${url || '-'}

    Clearance Calculation:
    Ship's draft at departure: ${formatNum(a.boat_draft)} m
    Under-keel clearance safety margin (0.75m summer / 1.00m winter): ${formatNum(a.tolerance)} m
    Total of the draft plus the under-keel clearance safety margin: ${formatNum(
        +a.boat_draft + +a.tolerance
    )} m
    Depth of Berth ${a?.dock?.label || '-'}: ${formatNum(a.dock_depth || a?.dock?.depth) || '?'} m
    Minimum tide at which the ship must depart: ${min_tide > 0 ? min_tide : '-'} m
	`)
}
