import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import AppRouter from 'router'

import 'antd/dist/antd.css'
import 'assets/scss/styles.scss'
import 'i18n/i18n'

import { Icon } from 'components'
import { CookiesProvider } from 'react-cookie'

ReactDOM.render(
    <Suspense
        fallback={
            <div className='translation-buffer'>
                <Icon>loading</Icon>
            </div>
        }
    >
        <CookiesProvider>
            <React.StrictMode>
                <AppRouter />
            </React.StrictMode>
        </CookiesProvider>
    </Suspense>,
    document.getElementById('root')
)
