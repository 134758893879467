export const formRules = t => ({
    required: { required: true, message: t('general.validation.required') },
    email: { type: 'email', message: t('general.validation.email') },
    url: {
        pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
        message: t('general.validation.url'),
    },
    phone: {
        pattern: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
        message: t('general.validation.phone'),
    },
    npa: { pattern: /^\d{3}$/, message: t('general.validation.npa') },
    hex: { pattern: /^#([a-zA-Z0-9]{3,6})$/, message: t('general.validation.hex') },
    postal_code: {
        pattern: /^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}$/,
        message: t('general.validation.postal_code'),
    },
    ip: {
        pattern: /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        message: t('general.validation.ip'),
    },
})
