import { Helmet, Header, Icon, AnalysisForm } from 'components'
import { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Service } from 'services'
import { LineChart, Timeline, TidesTable } from 'graphs'
import { history, GraphActions, LastUpdated, handleAnalysisMailing, transformTimeStep } from 'utils'
import moment from 'moment'
import { DATE_TIME_FORMAT, FORMAT_LONG_DATE, REFRESH_RATE } from 'constants/app'
import { DatePicker, Select, Slider, message, Collapse, Button, Modal, Tooltip } from 'antd'
import CountUp from 'react-countup'
import * as htmlToImage from 'html-to-image'

function OverviewPage(props) {
    // Data sets --->
    const [docks, setDocks] = useState([])
    const [tides, setTides] = useState([])
    const [analysis, setAnalysis] = useState({})
    const analysisRef = useRef(analysis)
    analysisRef.current = analysis

    // URL params -->
    const query = new URLSearchParams(useLocation().search)
    const baseDateTmp = moment(query.get('date'), DATE_TIME_FORMAT, true)
    const isPrint = query.get('print') === '1'

    // Filter + Graph states --->
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [baseDate, setBaseDate] = useState(baseDateTmp.isValid() ? baseDateTmp : null)
    const [dateOffset, setDateOffset] = useState([-12, 48])
    const [dateOffsetTmp, setDateOffsetTmp] = useState(dateOffset)
    const [dockDepth, setDockDepth] = useState(0)
    const [lastUpdated, setLastUpdated] = useState(null)
    const [editModalOpened, setEditModalOpened] = useState(false)

    // Analyses
    const [canMail, setCanMail] = useState(false)
    const [isWarningModalVisible, setIsWarningModalVisible] = useState(false)
    const [isShortRange, setIsShortRange] = useState(false)
    const [usePlaceholderIcons, setUsePlaceholderIcons] = useState(false)

    // Others --->
    const { t } = useTranslation()
    const { alias } = props.match.params
    let mode = alias ? 'analysis' : 'home'

    // Fetchers --->
    const fetchAnalysis = async alias => {
        try {
            const data = await Service.get.analysis(alias)
            setAnalysis({
                ...data,
                dock_num: data?.dock?.label.replace('Quai ', '') || 0,
            })
        } catch (e) {
            console.error(e)
            history.push('/')
        }
    }

    const fetchDocks = async () => {
        if (mode === 'analysis') return
        try {
            const docks = await Service.list.docks()
            setDocks(docks)
        } catch (e) {
            console.error(e)
        }
    }

    const fetchTides = async () => {
        try {
            setError(false)
            const data = await Service.list.levels(baseDate, dateOffset)
            setTides(data)
            setLoading(false)

            const status = await Service.get.status()
            setLastUpdated(
                status?.api?.last_update
                    ? moment(status?.api?.last_update.replace(' UTC', 'Z'))
                    : null
            )
        } catch (e) {
            setLoading(false)
            setError(true)
            console.error(e)
        }
    }

    useEffect(() => {
        analysisRef.current = analysis
    }, [analysis])

    // Listeners --->
    useEffect(() => {
        fetchDocks()
    }, [])

    // Listeners TEST --->
    useEffect(() => {
        if (alias) fetchAnalysis(alias)
        else setAnalysis({})
    }, [alias])

    useEffect(() => {
        fetchTides()
        const timer = setInterval(fetchTides, REFRESH_RATE)
        return () => clearInterval(timer)
    }, [baseDate, dateOffset, dockDepth, analysis.id])

    // Events Handlers --->
    const handleAreaClick = async (range, shoal) => {
        try {
            const range_shoal = findTimeRangeIndex(range, shoal)
            const updated = await GraphActions.analysis.handleAreaClick(
                analysis.alias,
                range,
                range_shoal
            )

            setAnalysis({
                ...analysisRef.current,
                start_manoeuver_hour: updated.start_manoeuver_hour,
                end_manoeuver_hour: updated.end_manoeuver_hour,
                start_manoeuver_hour_pilot: updated.start_manoeuver_hour_pilot,
                end_manoeuver_hour_pilot: updated.end_manoeuver_hour_pilot,
                highest_tides: updated.highest_tides,
            })

            message.success(t('general.feedback.update.title'))
            setIsShortRange(false)

            if (range[range.length - 1].boatDraft - range[0].boatDraft > 0.1) {
                setIsShortRange(true)
                message.warn({
                    content: t('general.feedback.warning_area.title'),
                    duration: 8,
                    style: { maxWidth: 400, lineHeight: 1.2, margin: '0 auto' },
                })
            }
            setCanMail(true)
            return true
        } catch (e) {
            message.error(t('general.feedback.error.title'))
            return false
        }
    }

    function findTimeRangeIndex(range, shoalRange) {
        if (shoalRange.length === 0) return []
        const minTime = range[0].date.getTime()
        const maxTime = range[1].date.getTime()

        const index = shoalRange.findIndex(timeRange => {
            const targetMinTime = new Date(timeRange.min).getTime()
            const targetMaxTime = new Date(timeRange.max).getTime()
            return targetMinTime >= minTime && targetMaxTime <= maxTime
        })

        let new_range = []
        if (index !== -1)
            new_range = [{ date: shoalRange[index].min }, { date: shoalRange[index].max }]

        return new_range
    }

    const handleMailButtonClick = async () => {
        if (isShortRange) setIsWarningModalVisible(true)
        else {
            const image = await handleImgToPng('linegraph-print-target')
            handleAnalysisMailing(analysis, t, image)
        }
    }

    const handleExport = async () => {
        const image = await handleImgToPng('linegraph-print-target')
        const newWindow = window.open('', '_blank')
        newWindow.document.write(`<img src="${image.src}" alt="Exported Image"/>`)
    }

    // Utils --->
    const computeAnalysisNumber = key => {
        if (mode !== 'analysis') return null
        if (key === 'end_manoeuver_hour') return analysis[key] ? new Date(analysis[key]) : null
        return parseFloat(analysis[key] || 0)
    }

    const handleImgToPng = async id => {
        const node = document.getElementById(id)
        if (!node) {
            console.error('Node not found')
            return
        }

        try {
            setUsePlaceholderIcons(true)
            await new Promise(resolve => setTimeout(resolve, 0))

            const dataUrl = await htmlToImage.toPng(node)
            let img = new Image()
            img.src = dataUrl

            setUsePlaceholderIcons(false)
            await new Promise(resolve => setTimeout(resolve, 0))
            return img
        } catch (error) {
            console.error('oops, something went wrong!', error)
            setUsePlaceholderIcons(false)
        }
    }

    return (
        <div className='overview-page'>
            <Helmet title={t('page_titles.overview')} />
            <OverviewHeader mode={mode} analysis={analysis} isPrint={isPrint} />
            <div id='analysis-print-target'>
                <AnalysisStats
                    mode={mode}
                    analysis={analysis}
                    isPrint={isPrint}
                    tides={tides}
                    isPlaceholder={usePlaceholderIcons}
                />

                {mode === 'analysis' && analysis.id ? (
                    <div className='analysis-actions'>
                        <Button
                            onClick={() => setEditModalOpened(true)}
                            className='analysis-edit-btn'
                        >
                            <Icon>edit</Icon>
                            {t('general.actions.edit')}
                        </Button>

                        <Tooltip
                            title={!canMail ? t('general.actions.mail_tooltip') : ''}
                            mouseEnterDelay={0.5}
                        >
                            <Button
                                onClick={() => handleMailButtonClick()}
                                className='analysis-mailing-btn'
                                disabled={!canMail}
                            >
                                <Icon>mail</Icon>
                                {t('general.actions.send_mail')}
                            </Button>
                        </Tooltip>
                    </div>
                ) : (
                    <></>
                )}

                {mode === 'analysis' ? (
                    <div>
                        <Modal
                            visible={editModalOpened}
                            onCancel={() => setEditModalOpened(false)}
                            header={null}
                            footer={null}
                            className='modal-analysis-form-wrap'
                        >
                            <>
                                <AnalysisForm
                                    alias={analysis.alias}
                                    onSubmit={a => {
                                        setAnalysis({ ...analysis, ...a })
                                        setCanMail(false)
                                        setEditModalOpened(false)
                                    }}
                                    baseValues={analysis}
                                    title={t('overview_page.edit_analysis')}
                                />
                            </>
                        </Modal>
                        <Modal
                            title={
                                <span style={{ fontSize: '20px', verticalAlign: 'middle' }}>
                                    <Icon
                                        style={{
                                            color: '#ff8f00',
                                            marginRight: 8,
                                            fontSize: '35px',
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        warning
                                    </Icon>
                                    <b>{t('general.actions.area_warning_modal_title')}</b>
                                </span>
                            }
                            visible={isWarningModalVisible}
                            onOk={async () => {
                                setIsWarningModalVisible(false)
                                const image = await handleImgToPng('linegraph-print-target')
                                handleAnalysisMailing(analysis, t, image)
                            }}
                            onCancel={() => setIsWarningModalVisible(false)}
                            okText={t('general.actions.continue')}
                            cancelText={t('general.actions.back')}
                            cancelButtonProps={{ style: { color: 'white' } }}
                        >
                            <p style={{ fontSize: '16px' }}>
                                {t('general.actions.area_warning_modal_dsc')}
                            </p>
                        </Modal>
                    </div>
                ) : (
                    <></>
                )}

                <article id='graph-print-target'>
                    <div className='overview-graph-title'>
                        <h2>
                            <Icon onClick={handleExport} placeholder={usePlaceholderIcons}>
                                print
                            </Icon>
                            {t('overview_page.graph_title')}
                        </h2>

                        <div className='date-manager'>
                            <div className='date-dock-wrap'>
                                {mode === 'home' && (
                                    <div className='dock-input'>
                                        <label>{t('general.dock')} : </label>
                                        <Select
                                            allowClear
                                            onChange={id =>
                                                setDockDepth(
                                                    id
                                                        ? parseFloat(
                                                              docks.find(d => d.id === id).depth
                                                          ) || 0
                                                        : 0
                                                )
                                            }
                                        >
                                            {docks.map(dock => (
                                                <Select.Option key={dock.id} value={dock.id}>
                                                    {dock.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                )}
                                <DatePicker
                                    defaultValue={baseDate}
                                    showTime
                                    placeholder={moment().format(DATE_TIME_FORMAT)}
                                    onChange={value => {
                                        setBaseDate(value)
                                        setCanMail(false)
                                    }}
                                    format={DATE_TIME_FORMAT}
                                />
                            </div>
                            <Slider
                                range={{ draggableTrack: true }}
                                value={dateOffsetTmp}
                                onChange={values =>
                                    values[1] - values[0] > 12 ? setDateOffsetTmp(values) : null
                                }
                                onAfterChange={values => {
                                    setDateOffset(values)
                                    setCanMail(false)
                                }}
                                marks={{
                                    '-24': '-24h',
                                    0: '0h',
                                    24: '+24h',
                                    48: '+48h',
                                    72: '+72h',
                                }}
                                min={-24}
                                max={72}
                                included={true}
                            />
                        </div>
                    </div>
                    <div className='overview-graph'>
                        <LineChart
                            id='line_chart'
                            width='960'
                            height='500'
                            data={tides}
                            loading={loading}
                            error={error}
                            tolerance={computeAnalysisNumber('tolerance')}
                            dockDepth={computeAnalysisNumber('dock_depth') || dockDepth}
                            boatDraft={computeAnalysisNumber('boat_draft')}
                            startHour={computeAnalysisNumber('end_manoeuver_hour')}
                            dockShoal={computeAnalysisNumber('dock_shoal')}
                            handleAreaClick={handleAreaClick}
                        />

                        {mode === 'analysis' ? (
                            <Timeline
                                raw={tides}
                                width='960'
                                height='180'
                                data={tides}
                                loading={loading}
                                error={error}
                                tolerance={computeAnalysisNumber('tolerance')}
                                dockDepth={computeAnalysisNumber('dock_depth') || dockDepth}
                                boatDraft={computeAnalysisNumber('boat_draft')}
                                startHour={computeAnalysisNumber('end_manoeuver_hour')}
                                dockShoal={computeAnalysisNumber('dock_shoal')}
                                handleAreaClick={handleAreaClick}
                            />
                        ) : (
                            <></>
                        )}

                        <LastUpdated date={lastUpdated} />
                    </div>
                </article>
            </div>

            {mode === 'analysis' ? (
                <Collapse bordered={false}>
                    <Collapse.Panel header={t('overview_page.panel_tides_levels_title')}>
                        <article className='analysis-block'>
                            <TidesTable
                                data={transformTimeStep(tides, 15)}
                                loading={loading}
                                error={error}
                                boatDraft={computeAnalysisNumber('boat_draft')}
                                dockDepth={computeAnalysisNumber('dock_depth') || dockDepth}
                            />
                            <LastUpdated date={lastUpdated} />
                        </article>
                    </Collapse.Panel>
                    <Collapse.Panel header={t('overview_page.panel_highest_tides_title')}>
                        <article className='analysis-block'>
                            <TidesTable
                                data={
                                    analysis.highest_tides ? JSON.parse(analysis.highest_tides) : []
                                }
                                highest
                                loading={loading}
                                error={error}
                            />
                        </article>
                    </Collapse.Panel>
                </Collapse>
            ) : (
                <></>
            )}
        </div>
    )
}

// Utils components
function OverviewHeader(props) {
    const { t } = useTranslation()
    const { mode, analysis, isPrint } = props

    return mode === 'home' ? (
        <Header
            title={t('overview_page.title')}
            subtitle={<>{t('overview_page.subtitle')}</>}
            adminLink
        />
    ) : (
        <Header
            pretitle={FORMAT_LONG_DATE(analysis.date)}
            title={
                <>
                    {analysis.boat_name}
                    <div className='dock-num-wrap'>
                        <small>{t('general.dock')}</small>
                        <span className='circle'>{analysis.dock_num}</span>
                    </div>
                </>
            }
            subtitle={t('overview_page.analysis_subtitle')}
            homeLink
            dashLink
            hideNav={isPrint}
        />
    )
}

function AnalysisStats(props) {
    const { t } = useTranslation()
    const { mode, analysis, tides, isPrint, isPlaceholder } = props

    if (mode !== 'analysis') return <></>

    const data = {
        dock_depth: parseFloat(analysis?.dock_depth || 0),
        dock_shoal: parseFloat(analysis?.dock_shoal || 0),
        tolerance: parseFloat(analysis?.tolerance || 0),
        boat_draft: parseFloat(analysis?.boat_draft || 0),
        end_manoeuver_hour: analysis?.end_manoeuver_hour || null,
        start_manoeuver_hour: analysis?.start_manoeuver_hour || null,
        end_manoeuver_hour_pilot: analysis?.end_manoeuver_hour_pilot || null,
        start_manoeuver_hour_pilot: analysis?.start_manoeuver_hour_pilot || null,
    }

    data.tide_at_start = GraphActions.analysis.getTideAtHour(data.end_manoeuver_hour, tides)
    if (!data.tide_at_start)
        data.tide_at_start = -data.dock_depth + data.tolerance + data.boat_draft

    if (!isNaN(data.tide_at_start)) data.tide_at_start = parseFloat(data.tide_at_start).toFixed(2)

    return (
        <>
            <article className='analysis-blocks'>
                <div>
                    <Icon unsafe placeholder={isPlaceholder}>
                        opacity
                    </Icon>
                    <CountUp
                        className='counter'
                        decimal=','
                        decimals={2}
                        end={data.dock_depth}
                        suffix=' m'
                        duration={isPrint ? 0.1 : 2}
                    />
                    <small>{t('general.dock_depth')}</small>
                </div>

                <div>
                    <Icon unsafe placeholder={isPlaceholder}>
                        flag
                    </Icon>
                    <CountUp
                        className='counter'
                        decimal=','
                        decimals={2}
                        end={data.tolerance}
                        suffix=' m'
                        duration={isPrint ? 0.1 : 2}
                    />
                    <small>{t('general.min_security_clearance')}</small>
                </div>

                <div>
                    <Icon unsafe placeholder={isPlaceholder}>
                        directions_boat
                    </Icon>
                    <CountUp
                        className='counter'
                        decimal=','
                        decimals={2}
                        end={data.boat_draft}
                        suffix=' m'
                        duration={isPrint ? 0.1 : 2}
                    />
                    <small>{t('general.boat_draft_start')}</small>
                </div>

                <div className='larger'>
                    <Icon unsafe placeholder={isPlaceholder}>
                        schedule
                    </Icon>
                    {data.end_manoeuver_hour ? (
                        <div className='infos-flex'>
                            <div className='infos-flex-item'>
                                <span className='details'>
                                    {FORMAT_LONG_DATE(data.end_manoeuver_hour, false)}
                                </span>
                                <span className='counter date'>
                                    {moment(data.end_manoeuver_hour).format('HH:mm')}
                                </span>
                                <small>{t('general.max_start_manoeuver')}</small>
                            </div>
                            <div className='infos-flex-item'>
                                <CountUp
                                    className='counter'
                                    decimal=','
                                    decimals={2}
                                    end={parseFloat(data.tide_at_start) || 0}
                                    suffix=' m'
                                    duration={isPrint ? 0.1 : 2}
                                />
                                <small>{t('general.tide_at_time')}</small>
                            </div>
                        </div>
                    ) : (
                        <span className='counter'>-</span>
                    )}
                </div>
            </article>
        </>
    )
}

export default OverviewPage
