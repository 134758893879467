import { Router, Switch, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ErrorBoundary, Footer } from 'components'
import { history } from 'utils'

import { OverviewPage, AdminPage, DockPage } from 'pages'
import { ConfigProvider } from 'antd'

import fr_FR from 'antd/lib/locale-provider/fr_FR'
import en_US from 'antd/lib/locale-provider/en_US'

function AppRouter() {
    const { i18n } = useTranslation()

    const lng = i18n.language || ''
    const locale = lng === 'fr' ? fr_FR : en_US

    return (
        <div className='page-wrap'>
            <ConfigProvider locale={locale}>
                <Router history={history}>
                    <ErrorBoundary>
                        <Switch>
                            <Route exact path='/admin' component={AdminPage} />
                            <Route exact path='/admin/dock' component={DockPage} />
                            <Route exact path='/:alias' component={OverviewPage} key={1} />
                            <Route path='*' component={OverviewPage} />
                        </Switch>
                    </ErrorBoundary>
                    <Footer />
                </Router>
            </ConfigProvider>
        </div>
    )
}

export default AppRouter
