import { Helmet, Header, Table, AnalysisForm } from 'components'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import { useAuth } from 'hooks'
import { Service } from 'services'
import { ANALYSIS_LIST_TABLE_COLUMNS } from 'constants/tables'
import { history } from 'utils'

function AdminPage(props) {
    const { t } = useTranslation()
    const [analysis, setAnalysis] = useState([])
    const [defaultTolerance, setDefaultTolerance] = useState()

    const { isAuthentified, renderAuthRequest } = useAuth()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const analysis = await Service.list.analysis()
                setAnalysis(analysis.sort((a, b) => new Date(b.date) - new Date(a.date)))

                const lastUsedTolerance = analysis[0]?.tolerance
                if (lastUsedTolerance) setDefaultTolerance(parseFloat(lastUsedTolerance))
            } catch (e) {
                console.error(e)
                setAnalysis([])
            }
        }

        if (isAuthentified === true) fetchData()
    }, [isAuthentified])

    if (!isAuthentified)
        return (
            <div className='admin-page-login'>
                <Helmet title={t('page_titles.admin')} />
                <Header />
                {renderAuthRequest(t)}
            </div>
        )

    return (
        <div className='admin-page'>
            <Helmet title={t('page_titles.admin')} />
            <Header
                title={t('admin_page.title')}
                subtitle={t('admin_page.subtitle')}
                dockLink
                homeLink
                type='dots'
            />

            <AnalysisForm
                create
                onSubmit={a => {
                    // setAnalysis([a, ...analysis])
                    history.push('/' + a.alias)
                }}
                baseValues={{ tolerance: defaultTolerance }}
            />

            <Table
                title={t('admin_page.analysis_table')}
                columns={ANALYSIS_LIST_TABLE_COLUMNS(t)}
                data={analysis}
            />
        </div>
    )
}

export default AdminPage
