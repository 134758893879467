import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as d3 from 'd3'
import moment from 'moment'
import { DATE_FORMAT, DATE_FORMAT_API } from 'constants/app'
import { Overlays } from 'utils'

const getPeaks = (D, key) => {
    try {
        let dir = D[0]?.[key] > D[1]?.[key] ? 'down' : 'up'
        let pastPeak,
            toRemove = []

        return D.filter((d, i) => {
            const first = i <= 6
            const validation = () => {
                if (first) return false
                if (pastPeak && moment(d.date).isSame(D[pastPeak].date, 'hour')) {
                    if (d?.[key] < D[pastPeak]?.[key]) return false
                    else toRemove[toRemove.length - 1] = true
                }

                pastPeak = i
                toRemove.push(false)
                return true
            }

            const nextDiff = D[i + 1]?.[key] - D[i]?.[key]
            if (dir === 'down' && nextDiff > 0) {
                dir = 'up'
                return validation()
            }

            if (dir === 'up' && nextDiff < 0) {
                dir = 'down'
                return validation()
            }

            return false
        })
            .filter((d, i) => !toRemove[i])
            .map(d => ({ ...d, value: d[key], className: key }))
    } catch (e) {
        console.log(e)
        return []
    }
}

function LineChart(props) {
    const { t } = useTranslation()
    const { loading, error } = props
    const [datas, setDatas] = useState([])

    let svg,
        linesGroup,
        infosGroup,
        mouseGroup,
        dotsGroups,
        xScale,
        yScale,
        yScaleArea,
        wlo_line,
        wlf_line,
        wlp_line,
        boatDraft_line
    let margin = { top: 20, right: 20, bottom: 50, left: 50 },
        width = props.width - margin.left - margin.right,
        height = props.height - margin.top - margin.bottom

    const format = async () => {
        try {
            const { data } = props
            let parser = d3.timeParse('%Y-%m-%d %H:%M:%S')
            let data_temp = { wlo: [], wlp: [], wlf: [], boatDraft: [] }
            data.forEach(function (d) {
                d.date = parser(moment(d.date).format(DATE_FORMAT_API))
                d.wlo = +d.wlo
                d.wlp = +d.wlp
                d['wlf-spine'] = +d['wlf-spine']
                if (d.wlo !== 0) data_temp['wlo'].push(d)
                if (d.wlp !== 0) data_temp['wlp'].push(d)
                if (d['wlf-spine'] !== 0 && !d.wlo && d.date >= new Date()) data_temp['wlf'].push(d)
                if (props.boatDraft) {
                    let tide =
                        d.wlo !== 0 ? d.wlo : d.wlp >= d['wlf-spine'] ? d['wlf-spine'] : d.wlp
                    if (tide) {
                        let boatDraft = tide - props.boatDraft
                        data_temp['boatDraft'].push({ date: d.date, boatDraft: boatDraft })
                    }
                }
            })

            setDatas(data_temp)
        } catch (e) {
            console.error(e)
        }
    }

    const get_seuil_areas = function (value) {
        let areas = []
        let i = 0
        let in_area = false
        let last_boatDraft
        let now = moment()
        datas.boatDraft.forEach(function (d) {
            if (d.date > now) {
                if (d.boatDraft >= value) {
                    last_boatDraft = d.boatDraft
                    if (in_area === false) {
                        areas[i] = []
                        in_area = true
                    }
                    areas[i].push(d)
                } else {
                    if (in_area === true) {
                        last_boatDraft = 0
                        in_area = false
                        i = i + 1
                    }
                }
            }
        })
        return areas
    }

    const initScales = () => {
        // Calcule des domains (valeur min max)
        xScale = d3.scaleTime().range([0, width])
        yScale = d3.scaleLinear().range([height, 0])
        yScaleArea = d3.scaleLinear().range([height, 0])

        if (datas.length !== 0) {
            let date_min = d3.min([datas.wlo[0]?.date, datas.wlf[0]?.date, datas.wlp[0]?.date])
            let date_max = d3.max([
                datas.wlo[datas.wlo.length - 1]?.date,
                datas.wlf[datas.wlf.length - 1]?.date,
                datas.wlp[datas.wlp.length - 1]?.date,
            ])
            xScale.domain([date_min, date_max])
            let depth_min = props.dockDepth ? -props.dockDepth : 0
            yScale.domain([
                depth_min,
                d3.max(datas.wlp, function (d) {
                    return Math.max(d.wlo, d.wlp, d['wlf-spine']) + 1.0
                }),
            ])

            if (props.boatDraft)
                yScaleArea.domain([depth_min, d3.max(datas.boatDraft, d => d.boatDraft)])
        }
    }

    const initLines = () => {
        // line WLO
        wlo_line = d3
            .line()
            .curve(d3.curveMonotoneX)
            .x(function (d) {
                return xScale(d.date)
            })
            .y(function (d) {
                return yScale(d.wlo)
            })

        // line WLP
        wlp_line = d3
            .line()
            .curve(d3.curveMonotoneX)
            .x(function (d) {
                return xScale(d.date)
            })
            .y(function (d) {
                return yScale(d.wlp)
            })

        // line WLF-SPINE
        wlf_line = d3
            .line()
            .curve(d3.curveMonotoneX)
            .x(function (d) {
                return xScale(d.date)
            })
            .y(function (d) {
                return yScale(d['wlf-spine'])
            })

        // line BOAT-DRAFT
        if (props.boatDraft) {
            boatDraft_line = d3
                .line()
                .curve(d3.curveMonotoneX)
                .x(function (d) {
                    return xScale(d.date)
                })
                .y(function (d) {
                    return yScale(d['boatDraft'])
                })
        }
    }

    const appendAxes = () => {
        svg.append('g')
            .attr('id', 'xAxis')
            .attr('transform', 'translate(0,' + height + ')')

        svg.append('g')
            .attr('id', 'xAxis-date')
            .attr('transform', 'translate(0,' + (height + 20) + ')')

        svg.append('g').attr('id', 'yAxis')

        svg.append('line').attr('id', 'zero-emphasis').attr('x1', 0).attr('x1', width)

        svg.append('text')
            .attr('class', 'axis-legend')
            .attr('transform', `translate(${width / 2}, ${height + margin.bottom})`)
            .style('text-anchor', 'middle')
            .text(t('general.date_legend'))

        svg.append('text')
            .attr('class', 'axis-legend')
            .attr('transform', 'rotate(-90)')
            .attr('x', -height / 2)
            .attr('y', -margin.left + 10)
            .style('text-anchor', 'middle')
            .text(t('general.tide_legend'))
    }

    const appendElements = () => {
        linesGroup = svg.append('g').attr('id', 'lines')

        linesGroup.append('line').attr('class', 'line now')
        linesGroup.append('line').attr('class', 'line seuil')
        linesGroup.append('path').attr('class', 'line wlp')
        linesGroup.append('path').attr('class', 'line wlo')
        linesGroup.append('path').attr('class', 'line wlf')
        linesGroup.append('path').attr('class', 'line draft')

        infosGroup = svg.append('g').attr('id', 'contextual-infos')
        infosGroup.append('rect').attr('class', 'text-elem-bg seuil')
        infosGroup.append('rect').attr('class', 'text-elem-bg dock')
        infosGroup.append('text').attr('class', 'text-elem seuil')
        infosGroup.append('text').attr('class', 'text-elem dock')
        infosGroup.append('g').attr('class', 'intersections')
        infosGroup.append('g').attr('class', 'peaks')

        mouseGroup = svg.append('g').attr('id', 'mouse-over-effects')
        mouseGroup.append('path').attr('class', 'mouse-line')

        dotsGroups = mouseGroup.append('g').attr('id', 'dots')
        dotsGroups.append('circle').attr('class', 'dot wlp')
        dotsGroups.append('circle').attr('class', 'dot wlo')
        dotsGroups.append('circle').attr('class', 'dot wlf')
        dotsGroups.append('circle').attr('class', 'dot draft')

        mouseGroup
            .append('rect')
            .attr('width', width)
            .attr('height', height)
            .attr('fill', 'none')
            .attr('pointer-events', 'all')

        svg.append('g').attr('id', 'areas')
    }

    const updateLine = () => {
        // Add the wlo path.
        d3.select('.line.wlo').data([datas.wlo]).transition().attr('d', wlo_line)

        // Add the wlp path.
        d3.select('.line.wlp').data([datas.wlp]).transition().attr('d', wlp_line)

        // Add the wlf path.
        d3.select('.line.wlf').data([datas.wlf]).transition().attr('d', wlf_line)

        // Add the wlf path.
        d3.select('.line.now')
            .attr('x1', xScale(moment()))
            .attr('x2', xScale(moment()))
            .attr('y1', 0)
            .attr('y2', height)

        d3.select('.dot.wlo').data([datas.wlo])
        d3.select('.dot.wlp').data([datas.wlp])
        d3.select('.dot.wlf').data([datas.wlf])

        if (props.boatDraft) {
            d3.select('.line.draft').data([datas.boatDraft]).transition().attr('d', boatDraft_line)

            d3.select('.dot.draft').data([datas.boatDraft])
        }
    }

    const updateAxis = () => {
        // Add the X Axis
        d3.select('#xAxis').call(
            d3.axisBottom(xScale).ticks(d3.timeHour.every(3), '%Hh').tickSize(0)
        )

        d3.select('#xAxis-date').call(
            d3.axisBottom(xScale).ticks(d3.timeDay.every(1), '%d-%m').tickSize(0)
        )

        // Add the Y Axis
        d3.select('#yAxis').call(d3.axisLeft(yScale).tickSize(-width))
        d3.select('#zero-emphasis').attr('y1', yScale(0)).attr('y2', yScale(0))
    }

    const updateSeuil = () => {
        let value = -props.dockDepth + +props.tolerance
        let value_shoal = -props.dockShoal + +props.tolerance

        let value_scaled = yScale(value)
        d3.selectAll('.area').remove()

        let areas = get_seuil_areas(value)
        let areas_shoal = modifyArray(get_seuil_areas(value_shoal))

        d3.select('.line.seuil')
            .transition()
            .attr('x1', 0)
            .attr('y1', value_scaled)
            .attr('x2', width)
            .attr('y2', value_scaled)

        let curveFunc = d3
            .area()
            .x(function (d) {
                return xScale(d.date)
            })
            .y1(function (d) {
                return yScale(d.boatDraft)
            })
            .y0(value_scaled)

        areas.forEach(function (a) {
            d3.select('#areas')
                .append('path')
                .attr('class', function (d) {
                    if (!props.startHour) return 'area'
                    const date = moment(props.startHour)
                    const range = [moment(a[0].date), moment(a[a.length - 1].date)]

                    if (date.isBetween(range[0], range[1], undefined, '[]')) return 'area active'
                    return 'area'
                })
                .attr('data-area', new Date(a[a.length - 1].date).getTime())
                .attr('d', curveFunc(a))
                .on('click', async function () {
                    if (props.handleAreaClick) {
                        //if (d3.select(this).classed('active')) return

                        const lenght = a.length - 1
                        const status = await props.handleAreaClick(
                            [
                                { date: a[0].date, boatDraft: a[0].boatDraft },
                                { date: a[lenght].date, boatDraft: a[lenght].boatDraft },
                            ],
                            areas_shoal
                        )
                        if (!status) return
                        d3.selectAll(`[data-area]`).classed('active', false)
                        d3.selectAll(`[data-area="${d3.select(this).attr('data-area')}"]`).classed(
                            'active',
                            true
                        )
                    }
                })
        })
    }

    const updateContextualInfos = () => {
        // Peaks
        const peaks = [...getPeaks(datas.wlo, 'wlo'), ...getPeaks(datas.wlf, 'wlf-spine')]

        const d3Peaks = d3.select('.peaks').selectAll('.peak').data(peaks)

        d3Peaks.exit().remove()

        const d3PeaksEnter = d3Peaks
            .enter()
            .append('g')
            .attr('class', 'peak')
            .attr('transform', d => `translate(${xScale(d.date)}, ${yScale(d.value)})`)

        d3PeaksEnter.append('circle').attr('class', d => d.className)

        d3PeaksEnter
            .append('text')
            .text(d => d.value + ' m')
            .attr('class', d => d.className)
            .attr('y', 20)

        d3Peaks
            .transition()
            .attr('transform', d => `translate(${xScale(d.date)}, ${yScale(d.value)})`)

        d3Peaks.select('circle').attr('class', d => d.className)

        d3Peaks
            .select('text')
            .text(d => d.value + ' m')
            .attr('class', d => d.className)

        // Dock depth
        const depth = props.dockDepth ? -props.dockDepth : 0
        if (depth) {
            d3.select('.text-elem.dock')
                .text(`${t('general.dock_depth')} : ${depth} m`)
                .attr('x', 5)
                .attr('y', yScale(depth) - 7)

            let w = d3.select('.text-elem.dock').node().getBoundingClientRect().width
            d3.select('.text-elem-bg.dock')
                .attr('y', yScale(depth) - 22)
                .attr('width', w + 20)
        } else {
            d3.selectAll('.text-elem.dock, .text-elem-bg.dock').attr('x', -1000)
        }

        // Threshold
        if (!depth || !props.tolerance) return

        const threshold = depth + +props.tolerance
        d3.select('.text-elem.seuil')
            .text(`${t('general.threshold')} : ${threshold.toFixed(2)} m`)
            .attr('x', 5)
            .attr('y', yScale(threshold) - 7)

        let w = d3.select('.text-elem.seuil').node().getBoundingClientRect().width
        d3.select('.text-elem-bg.seuil')
            .attr('y', yScale(threshold) - 22)
            .attr('width', w + 20)

        // Intersections
        const intersections = []
            .concat(
                // aplatit les Array imbriqués en un seul Array plat
                ...get_seuil_areas(threshold).map(area => {
                    const end = { ...area[area.length - 1], dir: 'down' }
                    const start = { ...area[0], dir: 'up', end }
                    return [start, end]
                })
            )
            .filter(i => threshold - 0.2 <= i.boatDraft && i.boatDraft <= threshold + 0.2)

        const d3Intersections = d3
            .select('.intersections')
            .selectAll('.intersection')
            .data(intersections)

        d3Intersections.exit().remove()

        const TIME_TICKER_WIDTH = 28
        const isAreaTooSmall = d =>
            d.end != null && xScale(d.end.date) - xScale(d.date) < TIME_TICKER_WIDTH * 2

        const d3IntersectionsEnter = d3Intersections
            .enter()
            .append('g')
            .attr('class', d => {
                if (isAreaTooSmall(d)) return 'intersection hidden'

                const diff = moment(d.date).diff(moment(props.startHour), 'minutes')
                if (diff <= 2 && diff >= -2) return 'intersection active'

                return 'intersection'
            })
            .attr('data-area', d => new Date(d.date).getTime())
            .attr('transform', d => `translate(${xScale(d.date)}, ${yScale(threshold)})`)

        d3IntersectionsEnter.append('rect')

        d3IntersectionsEnter
            .append('text')
            .text(d => moment(d.date).format('HH:mm'))
            .attr('y', 20)
            .attr('x', d => (d.dir === 'down' ? -24 : 0))

        d3IntersectionsEnter
            .merge(d3Intersections)
            .transition()
            .attr('transform', d => `translate(${xScale(d.date)}, ${yScale(threshold)})`)
            .attr('class', d => {
                if (isAreaTooSmall(d)) return 'intersection hidden'

                const diff = moment(d.date).diff(moment(props.startHour), 'minutes')
                if (diff <= 2 && diff >= -2) return 'intersection active'

                return 'intersection'
            })

        d3Intersections
            .select('text')
            .text(d => moment(d.date).format('HH:mm'))
            .attr('x', d => (d.dir === 'down' ? -24 : 0))
    }

    const setupInteractions = () => {
        d3.selectAll('#areas, #mouse-over-effects')
            .on('mouseout', () => {
                d3.select('.mouse-line').classed('active', false)
                d3.selectAll('.dot').classed('active', false)
                d3.selectAll('#tooltip').classed('active', false)
            })
            .on('mouseover', () => {
                d3.select('.mouse-line').classed('active', true)
                d3.selectAll('.dot').classed('active', true)
                d3.selectAll('#tooltip').classed('active', true)
            })
            .on('mousemove', e => {
                const [x, y] = d3.pointer(e)
                const xDate = xScale.invert(x)
                const bisect = d3.bisector(d => d.date).left

                const keys = ['wlo', 'wlp', 'wlf', 'boatDraft']

                const infos = { date: xDate }

                keys.forEach((key, i) => {
                    const dataKey = key === 'wlf' ? 'wlf-spine' : key
                    const dataSet = datas[key]

                    const data = dataSet[bisect(dataSet, xDate)]
                    infos[key] = (data ? data[dataKey] : null) || null

                    let translate
                    if (!data || !data[dataKey]) {
                        infos[key] = null
                        translate = `translate(-2000, -2000)`
                    } else {
                        infos[key] = data[dataKey]
                        translate = `translate(${xScale(data.date)}, ${yScale(data[dataKey])})`
                    }

                    if (key === 'boatDraft') key = 'draft'
                    d3.select('.dot.' + key).attr('transform', translate)
                })

                d3.select('.mouse-line').attr('d', () => `M${x},${height} ${x},${0}`)

                if (infos.boatDraft && props.dockDepth)
                    infos.clearance = +props.dockDepth + infos.boatDraft

                updateTooltipContent([x, y], e, infos)
            })
    }

    const updateTooltipContent = ([x, y], event, infos) => {
        const tooltip = d3.select('#tooltip')

        if (event.clientX > window.innerWidth - 230)
            tooltip
                .style('left', event.clientX - 20 - 200 + 'px')
                .style('top', event.clientY - 20 + 'px')
        else
            tooltip.style('left', event.clientX + 20 + 'px').style('top', event.clientY - 20 + 'px')

        const f = v => (v ? parseFloat(v).toFixed(2) : '—')
        tooltip.select('.date-fill').html(moment(infos.date).format(DATE_FORMAT + ' HH:mm'))
        tooltip.select('.wlo-tooltip b').html(f(infos.wlo))
        tooltip.select('.wlf-tooltip b').html(f(infos.wlo ? null : infos.wlf))
        tooltip.select('.wlp-tooltip b').html(f(infos.wlp))
        tooltip.select('.clearance-tooltip b').html(f(infos.clearance))
    }

    const initializeChart = () => {
        svg = d3
            .select('#line-chart')
            .append('g')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        initVar()
        appendAxes()
        appendElements()
    }

    const initVar = () => {
        initScales()
        initLines()
    }

    const updateChart = () => {
        initVar()
        updateLine()
        updateAxis()
        updateContextualInfos()
        setupInteractions()
        if (props.dockDepth && props.tolerance) updateSeuil()
    }

    useEffect(() => {
        if (d3.select('#line-chart').html() === '') initializeChart()
    }, [])

    useEffect(() => {
        if (props.data.length > 0) format()
    }, [props.data, props.boatDraft])

    useEffect(() => {
        if (datas.length !== 0) updateChart()
    }, [datas, props.tolerance, props.dockDepth, props.dockShoal])

    return (
        <div id='linegraph-print-target' className='line-chart-wrap'>
            <ul className='legend'>
                <li className='wlo-color'>
                    <span />
                    <i>{t('general.wlo')}</i>
                </li>
                <li className='wlf-color'>
                    <span />
                    <i>{t('general.wlf')}</i>
                </li>
                <li className='wlp-color'>
                    <span />
                    <i>{t('general.wlp')}</i>
                </li>
                {props.boatDraft && (
                    <li className='draft-color'>
                        <span />
                        <i>{t('general.boat_draft')}</i>
                    </li>
                )}
                {props.tolerance && props.dockDepth && (
                    <li className='threshold-color'>
                        <span />
                        <i>{t('general.threshold')}</i>
                    </li>
                )}
            </ul>

            <div className='line-chart-wrap-overflow-handler'>
                <svg id='line-chart' viewBox={`0 0 ${props.width} ${props.height}`} />
                <Overlays loading={loading} error={error} />
            </div>

            <div id='tooltip'>
                <b className='date-fill'></b>
                <ul>
                    <li className='wlo-tooltip'>
                        {t('general.wlo')} : <b></b>
                    </li>
                    <li className='wlf-tooltip'>
                        {t('general.wlf')} : <b></b>
                    </li>
                    <li className='wlp-tooltip'>
                        {t('general.wlp')} : <b></b>
                    </li>
                    <li className='clearance-tooltip'>
                        {t('general.security_clearance')} : <b></b>
                    </li>
                </ul>
            </div>
        </div>
    )
}

function modifyArray(array) {
    return array.map(subArray => {
        const firstElement = subArray[0]
        const lastElement = subArray[subArray.length - 1]
        return { min: firstElement.date, max: lastElement.date }
    })
}

export default LineChart
