import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { APP_SEPARATOR } from 'constants/app'

const PQHelmet = props => {
    const { t } = useTranslation()

    return (
        <Helmet>
            {props.title && (
                <title>{`${t('navigation.app_name')} ${APP_SEPARATOR} ${props.title}`}</title>
            )}
            {props.children}
        </Helmet>
    )
}

export default PQHelmet
